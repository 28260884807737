const PARSED_LAT = parseFloat(localStorage.getItem("latitude"));
const PARSED_LONG = parseFloat(localStorage.getItem("longitude"));

export const DEFAULT_LOCATION = {
  LAT: isNaN(PARSED_LAT) || !PARSED_LAT ? 28.5355 : PARSED_LAT,
  LONG: isNaN(PARSED_LONG) || !PARSED_LONG ? 77.391 : PARSED_LONG,
};

export const VEHICLE_TYPE = {
  TAXI: "TAXI",
  EXECUTIVE_TAXI: "EXECUTIVE_TAXI",
  AMBULANCE: "AMBULANCE",
  BOMBEROS: "BOMBEROS",
  DOCTOR: "DOCTOR",
  CARABINEROS: "CARABINEROS",
  GRUA: "GRUA",
  MECANICO: "MECANICO",
  PATROL: "PATROL",
  PATROL1: "PATROL1",
  PROMO: "PROMO",
  REDCROSS: "REDCROSS",
};

export const TRIP_STATUS = {
  pending: "PENDING",
  accepted: "ACCEPTED",
  declined: "DECLINED",
  started: "STARTED",
  completed: "COMPLETED",
  cancelled: "CANCELLED",
  // NEW STATUSES 
  active: 'ACTIVE',
  dispatched: 'DISPATCHED',
  solved: 'SOLVED',
  hidden: 'HIDDEN'
};

export const DRIVER_GENDER = {
  male: "MALE",
  female: "FEMALE",
  all: "ALL",
};
