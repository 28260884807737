import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  TableContainer,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import useAdmin from "../../hooks/useAdmin";
// import { DEFAULT_LOCATION } from "../../utils/config";

const INITIAL_ADMIN = {
  fullName: "",
  email: "",
  password: "",
};

const AdminModal = ({ openModal, setOpenModal, currentAdmin, isEdit }) => {
  const [isMobile] = useMediaQuery("(max-width: 425px)");
  const { updateAdmin, createAdmin } = useAdmin();

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().min(1).required(),
    email: Yup.string().min(1).required(),
    password: Yup.string().min(6).required(),
  });

  const [admin, setAdmin] = useState(INITIAL_ADMIN);

  useEffect(() => {
    setAdmin(currentAdmin);
  }, [currentAdmin]);

  const onClose = () => setOpenModal(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const _adminData = { ...admin };
    _adminData[name] = value;
    setAdmin(_adminData);
  };

  const handleSubmitInputs = (e) => {
    isEdit ? updateAdmin(admin) : createAdmin(admin);
    onClose();
    setAdmin(INITIAL_ADMIN);
    e.preventDefault();
  };

  return (
    <Modal isOpen={openModal} onClose={onClose} size={400}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody paddingTop="10" paddingBottom="10">
          <Box className="modal-open" paddingLeft="20">
            <Text fontSize={20} marginBottom="12">
              Set the admin
            </Text>
            <TableContainer>
              <Formik
                initialValues={admin}
                validationSchema={validationSchema}
                onSubmit={handleSubmitInputs}
              >
                <Form>
                  <Grid
                    templateColumns={
                      isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"
                    }
                    gap={isMobile ? 1 : 2}
                    style={{ marginBottom: 26 }}
                  >
                    <GridItem w="75%">
                      <Text className="inputColor">Full Name</Text>
                    </GridItem>
                    <GridItem w="75%">
                      <InputControl
                        name="fullName"
                        value={admin.fullName}
                        onChange={handleChange}
                      />
                    </GridItem>
                  </Grid>
                  <Grid
                    templateColumns={
                      isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"
                    }
                    gap={isMobile ? 1 : 2}
                    style={{ marginBottom: 26 }}
                  >
                    <GridItem w="75%">
                      <Text className="inputColor">Email</Text>
                    </GridItem>
                    <GridItem w="75%">
                      <InputControl
                        name="email"
                        value={admin.email}
                        onChange={handleChange}
                      />
                    </GridItem>
                  </Grid>
                  <Grid
                    templateColumns={
                      isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"
                    }
                    gap={isMobile ? 1 : 2}
                    style={{ marginBottom: 26 }}
                  >
                    <GridItem w="75%">
                      <Text className="inputColor">Password</Text>
                    </GridItem>
                    <GridItem w="75%">
                      <InputControl
                        name="password"
                        value={admin.password}
                        onChange={handleChange}
                      />
                    </GridItem>
                  </Grid>

                  <Container
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <ButtonGroup>
                      <SubmitButton colorScheme="primary" marginTop="4">
                        Save
                      </SubmitButton>
                    </ButtonGroup>
                  </Container>
                </Form>
              </Formik>
            </TableContainer>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AdminModal;
