import {
  extendTheme,
  theme as baseTheme,
} from "@chakra-ui/react";
import { colors } from "./colors";

const primary = {
  DEFAULT: "#333366",
  50: "#8B8BC5",
  100: "#7E7EBE",
  200: "#6363B1",
  300: "#4E4E9C",
  400: "#414181",
  500: "#333366",
  600: "#202041",
  700: "#0E0E1B",
  800: "#000000",
  900: "#000000",
};

export const theme = extendTheme(
  {
    config: {
      initialColorMode: "light",
      useSystemColorMode: false,
      cssVarPrefix: "pickurpage",
    },
    colors: {
      ...colors,
      primary: primary,
    },
    fonts: {
      body: "Arial, sans-serif",
      heading: "Arial, sans-serif",
      mono: "Menlo, monospace",
    },
    styles: {
      global: {
        html: {
          scrollBehavior: "smooth",
        },
        body: {},
      },
    },
  },

  // withDefaultColorScheme({ colorScheme: 'blue' })
);
