import constant from "../constant";
import useSWR from "swr";

const useEmergencyRequest = ({ isShowAll }) => {
  const { data: emergencyRequests, error: apiError } = useSWR(
    `${constant.BASE_URL_ADMIN}/contact?isShowAll=${isShowAll}`,
  );
  return {
    emergencyRequests,
    errorInEmergencyRequests: apiError,
  };
};

export default useEmergencyRequest;
