import axios from 'axios';
import constant from '../constant';

// Create an instance
const axiosInstance = axios.create({});

// Add interceptor to attach token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    // Add your token logic here
    config.headers.token8080 = localStorage.getItem("access_token")
    config.headers.subdomainprefix = constant.SUB_DOMAIN_PREFIX
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Export the instance
export default axiosInstance;