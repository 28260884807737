import { SkeletonText, VStack } from "@chakra-ui/react";
import { useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useMemo } from "react";
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from "react-router-dom";
// import BookingPage from "./pages/BookingPage";
import AdminDrivers from "./pages/AdminDrivers";
import AdminEmergencies from "./pages/AdminEmergencies";
import AdminUsers from "./pages/AdminUsers";
import CheckoutPage from "./pages/CheckoutPage";
import ConfirmSPBooking from "./pages/ConfirmSPBooking";
import HomeWithNav from "./pages/HomeWithNav";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import { getHostname } from "./utils/domainName"; // Import the utility function

const RedirectToHome = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/");
    }
  }, []);
  return children;
};

function App() {

  useEffect(() => {
    getHostname(); // Call the utility function to log the hostname
  }, []);

  const libraries = useMemo(() => ["places"], []);
  const { isLoaded } = useJsApiLoader({
    // @ts-ignore
    libraries: libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  if (!isLoaded) {
    return (
      <VStack>
        <SkeletonText />
      </VStack>
    );
  }
  return (
    <BrowserRouter>
      <RedirectToHome>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/user" element={<HomeWithNav />} />
          <Route path="/drivers" element={<AdminDrivers />} />
          <Route path="/emergencies" element={<AdminEmergencies />} />
          <Route path="/users" element={<AdminUsers />} />
          {/* <Route path="/booking" element={<BookingPage />} /> */}
          <Route path="/confirm-booking" element={<ConfirmSPBooking />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </RedirectToHome>
    </BrowserRouter>
  );
}

export default App;
