import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import constant from "../constant";
import axiosInstance from "../interceptor";

const useAuthentication = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const loginUser = async (payload) => {
    // event.preventDefault()
    try {
      const res = await axiosInstance.post(
        `${constant.BASE_URL_ADMIN}/organization-user/login`,
        { ...payload, linked_monitor: constant.SUB_DOMAIN_PREFIX },
      );

      if (res.data) {
        console.log('res.data', res.data)
        localStorage.setItem("access_token", res.data.token);
        const monitor = await axiosInstance.get(
          `${constant.BASE_URL_ADMIN}/monitor/${constant.SUB_DOMAIN_PREFIX}`,
        );
        localStorage.setItem("latitude", monitor.data?.latitude);
        localStorage.setItem("longitude", monitor.data?.longitude);
        localStorage.setItem("organization_name", monitor.data?.organization_name);
        localStorage.setItem("user_name", res.data?.fullName);
        localStorage.setItem("city", monitor.data?.city);
        setTimeout(() => window.location.reload(), 1000);
        navigate(res.data?.scope === "Admin" ? "drivers" : "user");
        toast({
          description: "Login successful!",
          duration: 2000,
          isClosable: true,
        });
      }
      // else{
      //   console.log("Error in Loginnn", res.statusText);
      //   toast({
      //     description: res.statusText,
      //     duration: 2000,
      //     isClosable: true,
      //   });
      // }
    } catch (e) {
      toast({
        description: e.response.data.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.log("Error in submitting form ca", e);
    }
  };

  const registerUser = async (payload) => {
    // event.preventDefault()
    payload.linked_monitor = constant.SUB_DOMAIN_PREFIX
    try {
      await axiosInstance.post(
        `${constant.BASE_URL_ADMIN}/organization-user/register`,
        payload,
      );
      toast({
        description: "User created succcessfully!",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      navigate("/");
    } catch (error) {
      console.log("Error in submitting form", error.response);

      toast({
        description: error.response.data.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const requestLinkForForgotPassword = async (payload) => {
    // event.preventDefault()
    payload.linked_monitor = constant.SUB_DOMAIN_PREFIX
    try {
      await axiosInstance.post(
        `${constant.BASE_URL_ADMIN}/organization-user/forgot-password`,
        payload,
      );
      toast({
        description: "Link to reset password sent successfully!",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      navigate("/");
    } catch (error) {
      console.log("Error in submitting form", error.response);

      toast({
        description: error.response.data.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };


  const resetPassword = async (payload) => {
    // event.preventDefault()
    payload.linked_monitor = constant.SUB_DOMAIN_PREFIX
    try {
      await axiosInstance.post(
        `${constant.BASE_URL_ADMIN}/organization-user/reset-password`,
        payload,
      );
      toast({
        description: "User created succcessfully!",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      navigate("/");
    } catch (error) {
      console.log("Error in submitting form", error.response);

      toast({
        description: error.response.data.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleNavigation = (route) => {
    navigate(route);
  };

  const logoutUser = async () => {
    localStorage.removeItem("access_token");
    navigate("/");
    toast({
      description: "Logout successful!",
      duration: 2000,
      isClosable: true,
    });
  };

  return {
    loginUser,
    logoutUser,
    registerUser,
    handleNavigation,
    requestLinkForForgotPassword,
    resetPassword
    // isUserAuthenticated,
  };
};

export default useAuthentication;
