const constant = {
  // BASE_URL: "https://contact-emergency.vercel.app/",
  // BASE_URL_ADMIN: "http://localhost:4000/v1/admin",
  // BASE_URL_CLIENT: "http://localhost:4000/v1/client",
  BASE_URL_ADMIN: "https://api.8080.cl/v1/admin",
  BASE_URL_CLIENT: "https://api.8080.cl/v1/client",
  SUB_DOMAIN_PREFIX: window.location.host.includes("localhost")
    ? "303"
    : window.location.host.split(".9911.cl")[0],
};
export default constant;
