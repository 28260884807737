import { useToast } from "@chakra-ui/react";
import { useState, useEffect, createContext, useContext } from "react";
import { DEFAULT_LOCATION } from "../utils/config";
import axiosInstance from "../interceptor";

const GeolocationContext = createContext({ position: null, error: null });

export const useGeolocation = () => useContext(GeolocationContext);

export default function GeolocationContextProvider({ children }) {
  const toast = useToast();
  const [position, setPosition] = useState(/** @type GeolocationPosition */ (null));
  const [error, setError] = useState(/**@type GeolocationPositionError */ null);

  useEffect(() => {
    if (error) {
      toast({
        title: "Error.",
        description: error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  // FOR GETTING USER LOCATION (Not needed at the moment)
  // useEffect(() => {
  //   let watchId;
  //   if ("geolocation" in navigator) {
  //     watchId = navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         console.log("got position from navigator");
  //         setPosition({
  //           coords: {
  //             latitude: DEFAULT_LOCATION.LAT,
  //             longitude: DEFAULT_LOCATION.LONG,
  //             accuracy: 0,
  //             altitude: 0,
  //             altitudeAccuracy: 0,
  //             heading: 0,
  //             speed: 0,
  //           },
  //           timestamp: new Date().getTime(),
  //         });
  //         setError(null);
  //       },
  //       (error) => {
  //         setError(error.message);
  //         setPosition(null);
  //       },
  //       {
  //         enableHighAccuracy: true,
  //       }
  //     );
  //   } else {
  //     setError("Geolocation not supported by device.");
  //   }
  //   return () => {
  //     if (watchId) {
  //       navigator.geolocation.clearWatch(watchId);
  //     }
  //   };
  // }, []);

  //API Call an API to get default position to center map
  useEffect(() => {
    position === null &&
      axiosInstance
        .get("https://geolocation-db.com/json/")
        .then((response) => {
          // console.log(response.data)
          // const location = {
          //   lat: response.data.latitude,
          //   lng: response.data.longitude,
          // }
          console.log("got position from api", response.data);
          if (position === null && response.data.latitude !== "Not found" && response.data.longitude !== "Not found") {
            console.log("setting position from api");
            setPosition({
              coords: {
                latitude: DEFAULT_LOCATION.LAT,
                longitude: DEFAULT_LOCATION.LONG,
                accuracy: 0,
                altitude: 0,
                altitudeAccuracy: 0,
                heading: 0,
                speed: 0,
              },
              timestamp: new Date().getTime(),
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    /* 
{
  "country_code": "IN",
  "country_name": "India",
  "city": "Lucknow",
  "postal": "226004",
  "latitude": 26.85,
  "longitude": 80.9167,
  "IPv4": "103.97.211.68",
  "state": "Uttar Pradesh"
} 
*/
  }, [position]);

  const value = { position, error };
  return <GeolocationContext.Provider value={value}>{children}</GeolocationContext.Provider>;
}

/* 
maximumAge
Is a positive long value indicating the maximum age in milliseconds of a possible cached position that is acceptable to return. If set to 0, it means that the device cannot use a cached position and must attempt to retrieve the real current position. If set to Infinity the device must return a cached position regardless of its age. Default: 0.

timeout
Is a positive long value representing the maximum length of time (in milliseconds) the device is allowed to take in order to return a position. The default value is Infinity, meaning that getCurrentPosition() won't return until the position is available.

enableHighAccuracy
Is a boolean value that indicates the application would like to receive the best possible results. If true and if the device is able to provide a more accurate position, it will do so. Note that this can result in slower response times or increased power consumption (with a GPS chip on a mobile device for example). On the other hand, if false, the device can take the liberty to save resources by responding more quickly and/or using less power. Default: false.

*/
