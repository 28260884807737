import { Button, CloseButton, IconButton } from "@chakra-ui/react";
import { FaEdit, FaTrash } from "react-icons/fa";
import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import AdminModal from "./AdminModal";
import AlertModal from "../AlertModal";
import useAdmin from "../../hooks/useAdmin";

export default function UsersTable({ data = [] }) {
  const columns = [
    {
      name: "Name",
      cell: (row) => row.fullName,
    },
    {
      name: "Email",
      cell: (row) => row.email,
    },
    {
      name: "Actions",
      cell: (item) => (
        <>
          <IconButton
            aria-label="Edit Admin"
            colorScheme="green"
            isRound
            size="sm"
            icon={<FaEdit />}
            marginRight={4}
            onClick={() => toggleAdminModal({ item, isEdit: true })}
          />
          <IconButton
            aria-label="Delete Admin"
            colorScheme="red"
            isRound
            size="sm"
            icon={<FaTrash />}
            onClick={() => handleDelete(item)}
          />
        </>
      ),
    },
  ];

  const AddUser = ({ onAdd }) => (
    <Button colorScheme="primary" size="sm" onClick={onAdd}>
      Add User
    </Button>
  );

  const actionsMemo = useMemo(
    () => (
      <AddUser onAdd={() => toggleAdminModal({ item: {}, isEdit: false })} />
    ),
    [],
  );

  const { deleteAdmin } = useAdmin();
  const [isShowAdminModal, setIsShowAdminModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [currentAdmin, setCurrentAdmin] = useState(false);

  const toggleAdminModal = ({ item, isEdit }) => {
    setIsShowAdminModal((prev) => !prev);
    setCurrentAdmin(item);
    setIsEdit(isEdit);
  };

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const handleDelete = (item) => {
    setIsShowDeleteModal((prev) => !prev);
    setCurrentAdmin(item);
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        pagination
        persistTableHead
        actions={actionsMemo}
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
      />
      <AdminModal
        openModal={isShowAdminModal}
        setOpenModal={setIsShowAdminModal}
        currentAdmin={currentAdmin}
        isEdit={isEdit}
      />
      <AlertModal
        isOpen={isShowDeleteModal}
        onClose={() => setIsShowDeleteModal(false)}
        onAction={() => deleteAdmin(currentAdmin._id)}
      />
    </>
  );
}
