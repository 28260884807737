import { Box, Flex } from "@chakra-ui/layout";
import { useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useSWR from "swr";
import EmergencyTable from "../components/Emergencies/EmergencyTable";
import LeftHome from "../components/Sidebar/LeftHome";
import TopNav from "../components/TopNav/TopNav";
import DriverTable from "../components/driver/DriverTable";
import useEmergencyRequest from "../hooks/useEmergencyRequest";
import api from "../lib/api-client";
import HomePage from "./HomePage";
import { useEmergencyReqDetail } from "../contexts/EmergencyReqDetailContext";

const HomeWithNav = () => {
  const [isMobile] = useMediaQuery("(max-width: 425px)");
  const [city, setCity] = useState("");
  const [organizationName, setOrganizationNmae] = useState("");

  // const isUserAuthenticated = useAuth()

  // const { data, error: apiError } = useSWR(
  //   `near-me-drivers-detail?lng=${JSON.parse(localStorage.getItem("longitude"))}&lat=${JSON.parse(localStorage.getItem("latitude"))}`,
  //   api
  //   )
  const { emergencyRequests } = useEmergencyRequest({ isShowAll: true });
  const { selectedEmergencyReqFromContext } = useEmergencyReqDetail();
  const [isGruaType, setIsGruaType] = useState(false)

  useEffect(() => {
    setCity(localStorage.getItem('city'));
    setOrganizationNmae(localStorage.getItem('organization_name'));
  }, []);

  useEffect(() => {
    if (
      selectedEmergencyReqFromContext &&
      Object.keys(selectedEmergencyReqFromContext).length > 0
    ) {
      setIsGruaType(selectedEmergencyReqFromContext?.rutNumber)
    }
  }, [selectedEmergencyReqFromContext]);

  return (
    <Flex direction={isMobile ? "column" : "row"}>
      <Box w={isMobile ? "100%" : "16vw"} zIndex={1} bg="#ffffff">
        <LeftHome />
      </Box>
      <Box w="100%" overflow="hidden">
        <Box h="40px" zIndex={1} bg="#ffffff" position="relative">
          <TopNav city={city} organizationName={organizationName} />
        </Box>
        {/* <HomePage /> */}
        <Box marginTop={20}>
          <EmergencyTable data={emergencyRequests} isGruaType={isGruaType} />
        </Box>
      </Box>
    </Flex>
  );
};

export default HomeWithNav;
