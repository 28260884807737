import { useTranslation } from "react-i18next";
import constant from "../constant";
import axiosInstance from "../interceptor";

const sendContactInformation = async (payload, updatedByText) => {
  // event.preventDefault()
  try {
    await axiosInstance.put(`${constant.BASE_URL_ADMIN}/contact`, payload);
    await axiosInstance.post(`${constant.BASE_URL_ADMIN}/contact/logs`, {
     requestId: payload.requestId, logDescription: `${updatedByText} ${JSON.parse(localStorage.getItem("user_name"))}`

    });
    // setContactInfo(defaultContactInfo)
  } catch (e) {
    console.log("Error in submitting form", e);
  }
};

const getSubdomain = () => {
  const locationParts = window.location.hostname.split(".");
  const isLocalHost = locationParts.slice(-1)[0] === "localhost";
  return locationParts.slice(0, isLocalHost ? -1 : -2).join("");
};

export { sendContactInformation, getSubdomain };
