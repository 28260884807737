import {
  Badge,
  CloseButton,
  Input,
  InputGroup,
  InputRightElement,
  Tab,
  TabList,
  Tabs
} from "@chakra-ui/react";
import { capitalize } from "lodash";
import moment from "moment/moment";
import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import constant from "../../constant";
import { useEmergencyReqDetail } from "../../contexts/EmergencyReqDetailContext";
import axiosInstance from "../../interceptor";
import { DRIVER_VERIFICATION_STATUS, getTripStatusBadgeColor } from "../../lib/config";
import ExpandedEmergencyDetails from "./ExpandedEmergencyDetails";

export default function EmergencyTable({ data = [], isGruaType }) {
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      minWidth: "100px",
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
      sortable: true,
      minWidth: "136px",
    },
    {
      name: "Location",
      selector: (row) => row.location,
      minWidth: "480px",
    },
    {
      name: "Requested On",
      selector: (row) => row.created,
      cell: (row) => moment(row.created).format("YYYY-MM-DD HH:mm"),
      sortable: true,
    },
    {
      name: "Status",
      // selector: row => row.verificationStatus,
      cell: (row) => (
        <Badge colorScheme={getTripStatusBadgeColor(row?.tripId?.status ?? "ACTIVE")}>
          {row?.tripId?.status ?? "ACTIVE"}
         <p> {moment(row?.tripId?.tripStartTime).format('YYYY/MM/DD HH:mm')}</p>
        </Badge>
      ),
      width: "4rem"                       // another for example

    },
    {
      name: "Visibility",
      cell: (row) => row.isSoftDelete ? <FaRegEyeSlash onClick={() => handleSoftDelete(row, false)} size={16} /> : <FaRegEye onClick={() => handleSoftDelete(row, true)} size={16}/>,
      center: true,
    },
  ];
  const { selectedEmergencyReqFromContext } = useEmergencyReqDetail();
  // const [isGruaType, setIsGruaType] = useState(false)
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = data.filter(
    (item) =>
      item.name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.drivingLicenseNumber
        .toLowerCase()
        .includes(filterText.toLowerCase()) ||
      item.vehicle.registrationNumber
        .toLowerCase()
        .includes(filterText.toLowerCase()) ||
      item.verificationStatus.toLowerCase().includes(filterText.toLowerCase()),
  );

  // useEffect(() => {
  //   if (
  //     selectedEmergencyReqFromContext &&
  //     Object.keys(selectedEmergencyReqFromContext).length > 0
  //   ) {
  //     setIsGruaType(selectedEmergencyReqFromContext?.rutNumber)
  //   }
  // }, [selectedEmergencyReqFromContext]);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleSoftDelete = async (request, isSoftDelete) => {
    try {
      await axiosInstance.put(
        `${constant.BASE_URL_ADMIN}/contact/visibility`,
        {
          id: request._id,
          isSoftDelete
        }
      );
      // if (res) {
      //   // toast({
      //   //   description: "Emergency updated successfully!",
      //   //   duration: 2000,
      //   //   isClosable: true,
      //   // });
        window.location.reload();
      // }
    } catch (e) {
      console.log("Error in submitting form", e);
    }
  };

  return (
    isGruaType ? <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        persistTableHead
        paginationResetDefaultPage={resetPaginationToggle}
        // subHeader
        // subHeaderComponent={subHeaderComponentMemo}
        // actions={actionsMemo}
        expandableRows
        expandableRowsComponent={ExpandedEmergencyDetails}
        onColumnOrderChange={(cols) => console.log(cols)}
      /> :
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        persistTableHead
        paginationResetDefaultPage={resetPaginationToggle}
        onColumnOrderChange={(cols) => console.log(cols)}
      /> 
  );
}

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <Tabs variant="enclosed">
      <TabList>
        <Tab onClick={onFilter} value={""}>
          All
        </Tab>
        <Tab onClick={onFilter} value={DRIVER_VERIFICATION_STATUS.approved}>
          {capitalize(DRIVER_VERIFICATION_STATUS.approved)}
        </Tab>
        <Tab onClick={onFilter} value={DRIVER_VERIFICATION_STATUS.pending}>
          {capitalize(DRIVER_VERIFICATION_STATUS.pending)}
        </Tab>
        <Tab onClick={onFilter} value={DRIVER_VERIFICATION_STATUS.declined}>
          {capitalize(DRIVER_VERIFICATION_STATUS.declined)}
        </Tab>
        <Tab onClick={onFilter} value={DRIVER_VERIFICATION_STATUS.blocked}>
          {capitalize(DRIVER_VERIFICATION_STATUS.blocked)}
        </Tab>
      </TabList>
    </Tabs>
    <InputGroup maxW="sm">
      <Input
        id="search"
        type="text"
        placeholder="Filter by (Name, Vehicle or DL number)"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
      <InputRightElement>
        <CloseButton
          type="button"
          onClick={onClear}
          display={filterText ? "inline-flex" : "none"}
        />
      </InputRightElement>
    </InputGroup>
  </>
);
