import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  VStack,
  chakra,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  Marker,
} from "@react-google-maps/api";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Geocode from "react-geocode";
import { useTranslation } from "react-i18next";

import { Navigate, useNavigate } from "react-router-dom";
import useSWR from "swr";
import Checkout from "../components/Checkout";
import DriverDetails from "../components/DriverDetails";
import Drivers from "../components/Drivers";
import LanguageSelector from "../components/LanguageSelector";
import { useBooking } from "../contexts/BookingContext";
import { useEmergencyReqDetail } from "../contexts/EmergencyReqDetailContext";
import { useGeolocation } from "../contexts/GeolocationContext";
import api from "../lib/api-client";
import { atlasMapStyle, snazzyMapStyle } from "../lib/map-style";
import { markerIcon } from "../lib/market-icon";
import { DEFAULT_LOCATION, DRIVER_GENDER, VEHICLE_TYPE } from "../utils/config";
import { drivers as baseDrivers } from "../utils/seed-drivers";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.enableDebug();

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: DEFAULT_LOCATION.LAT,
  lng: DEFAULT_LOCATION.LONG,
};

let position;

export default function ConfirmSPBooking({
  emergencyRequests,
  selectedEmergencyReq,
  setSelectedEmergencyReq,
}) {
  const { t } = useTranslation();

  const { sendSPPayload } = useEmergencyReqDetail();

  const {
    booking,
    distanceText,
    durationText,
    directionsResponse,
    fare,
    setDriver,
    setBooking,
  } = useBooking();
  const navigate = useNavigate();
  const toast = useToast();
  // const { position } = useGeolocation()
  const originRef = useRef(null);
  const destinationRef = useRef(null);

  const [origin, setOrigin] = useState({ location: "", address: "" });
  const [destination, setDestination] = useState({ location: "", address: "" });

  const [map, setMap] = useState(
    /** @type google.maps.Map*/
    (null),
  );

  const [directionsResponse2, setDirectionsResponse2] = useState(
    /** @type google.maps.DirectionsResult */ (null),
  );
  const [selectedDriver, setSelectedDriver] = useState(null);
  // const [drivers, setDrivers] = useState(() => baseDrivers)
  const [filteredDrivers, setFilteredDrivers] = useState([]);

  const { isOpen: shouldShowTaxi } = useDisclosure({ isOpen: true });

  useEffect(() => {
    setDriver(selectedDriver);
  }, [selectedDriver]);

  useEffect(() => {
    console.log('sendSPPayload["x-position"]', sendSPPayload["x-position"]);
    if (sendSPPayload["x-position"]) {
      position = sendSPPayload["x-position"];
    } else {
      console.log("No position / location");
    }
  }, [sendSPPayload]);

  useEffect(() => {
    const _getDetail = async () => {
      if (position) {
        const originRes = await Geocode.fromLatLng(
          JSON.parse(localStorage.getItem("latitude")),
          JSON.parse(localStorage.getItem("longitude")),
        );
        const originAddress = originRes.results[0]?.formatted_address;
        if (originRef.current?.value === "") {
          originRef.current.value = originAddress;
          setOrigin({
            location: originRes.results[0]?.geometry.location,
            address: originAddress,
          });
        }

        const destinationRes = await Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude,
        );
        const destinationAddress = destinationRes.results[0]?.formatted_address;
        console.log(destinationRes.results[0]);
        if (destinationRef.current?.value === "") {
          destinationRef.current.value = destinationAddress;
          setDestination({
            location: destinationRes.results[0]?.geometry.location,
            address: destinationAddress,
          });
        }
        const _booking = {
          requestId: sendSPPayload["x-id"],
          name: sendSPPayload["x-name"],
          phoneNumber: `+${sendSPPayload["x-phone-number"]}`,
          origin: {
            location: originRes.results[0]?.geometry.location,
            address: originAddress,
            isSPPicked: false,
          },
          destination: {
            location: destinationRes.results[0]?.geometry.location,
            address: destinationAddress,
          },
        };
        console.log("Inside Flow 1B");
        console.log("x -----------------> ", _booking);
        setBooking(_booking);
      }
    };
    _getDetail();
  }, [sendSPPayload["x-name"]]);

  useEffect(() => {
    if (booking === null) {
      navigate("/user");
    }
  }, [booking, navigate]);

  useEffect(() => {
    console.log("POS GOES HErE 1", { position, map });
    if (position != null && map != null) {
      console.log("POS GOES HErE", position);
      map.panTo({
        lat: parseFloat(position.coords.latitude),
        lng: parseFloat(position.coords.longitude),
      });
    }
  }, [position, map]);

  const onMapLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const directionsRendererOptions = useMemo(() => {
    return {
      directions: directionsResponse,
      polylineOptions: {
        strokeColor: "#FF0000",
        // strokeColor: '#0096FF',
        strokeOpacity: 1,
      },
    };
  }, [directionsResponse]);

  useEffect(() => {
    async function calculateRoute() {
      if (
        !booking ||
        booking.origin?.address === "" ||
        booking.destination?.address === "" 
      ) {
        return;
      }
      try {

        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService();
        const result = await directionsService.route({
          // origin: booking.origin.address,
          origin: booking.destination.address,
          destination: selectedEmergencyReq.destination,
          // eslint-disable-next-line no-undef
          travelMode: google.maps.TravelMode.DRIVING,
        });
        setDirectionsResponse2(result);
      } catch (error) {
        console.log(
          "error calculating directions in booking context",
          error.message,
        );
        setDirectionsResponse2(null);
      }
    }
    calculateRoute();
  }, [booking, sendSPPayload]);

  const directionsRendererOptions2 = useMemo(() => {
    return {
      directions: directionsResponse2,
      polylineOptions: {
        strokeColor: '#0096FF',
        strokeOpacity: 1,
      },
    };
  }, [directionsResponse]);


  const [mapCenter, setMapCenter] = useState({ latitude: 0, longitude: 0 });

  const [debouncedMapCenter] = useDebouncedValue(mapCenter, 500);

  useEffect(() => {
    console.log("debouncedMapCenter", debouncedMapCenter);
  }, [debouncedMapCenter]);

  const { data, error: apiError } = useSWR(
    debouncedMapCenter
      ? `/near-me-drivers?lng=${debouncedMapCenter.longitude}&lat=${debouncedMapCenter.latitude}`
      : null,
    api,
  );

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  const [vehicleType, setVehicleType] = useState(""); //Empty means all
  const [passengerCapacity, setPassengerCapacity] = useState(""); //0 means all
  const [acceptCreditCard, setAcceptCreditCard] = useState("2"); //2 means both
  const [gender, setGender] = useState(DRIVER_GENDER.all);
  const [speaksEnglish, setSpeaksEnglish] = useState(false);

  useEffect(() => {
    let filtered = data ?? [];
    console.log(passengerCapacity, vehicleType, acceptCreditCard, gender);
    if (vehicleType !== "" && vehicleType !== "ALL") {
      filtered = filtered.filter(
        (driver) => driver.vehicle.type === vehicleType,
      );
    }
    if (acceptCreditCard === "1") {
      filtered = filtered.filter((driver) => driver.acceptCreditCard === true);
    } else if (acceptCreditCard === "0") {
      filtered = filtered.filter((driver) => driver.acceptCreditCard === false);
    }
    if (passengerCapacity !== "") {
      filtered = filtered.filter(
        (driver) => driver.vehicle.passengerCapacity >= passengerCapacity,
      );
    }
    if (gender !== DRIVER_GENDER.all) {
      console.log(gender);
      filtered = filtered.filter((driver) => driver.gender === gender);
    }
    if (speaksEnglish === true) {
      filtered = filtered.filter((driver) => driver.speaksEnglish === true);
    }
    setFilteredDrivers(filtered);
  }, [
    vehicleType,
    passengerCapacity,
    acceptCreditCard,
    gender,
    speaksEnglish,
    data,
  ]);

  useEffect(() => {
    console.log("filteredLength", filteredDrivers.length);
  }, [filteredDrivers]);

  // console.log("position", position);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { height, width } = useViewportSize();

  // if (!booking) {
  //   return <Navigate to='/booking' replace={true} />
  // }

  const handlePersistZoomLevel = () => {
    if (map) {
      localStorage.setItem('map_zoom_level', map.getZoom())
    }
  }

  return (
    <>
      <VStack w={width} h={height}>
        <Box boxSize={"100%"} position="absolute" top={0} left={0}>
          <GoogleMap
            onCenterChanged={() =>
              map &&
              setMapCenter({
                latitude: map.getCenter().lat(),
                longitude: map.getCenter().lng(),
              })
            }
            onLoad={onMapLoad}
            id="direction-example"
            mapContainerStyle={containerStyle}
            zoom={!localStorage.getItem('map_zoom_level') || isNaN(localStorage.getItem('map_zoom_level')) ? 8 : parseInt(localStorage.getItem('map_zoom_level'))}
            onZoomChanged={handlePersistZoomLevel}
            center={center}
            options={{
              zoomControl: false,
              fullscreenControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              clickableIcons: false,
              styles: snazzyMapStyle,
            }}
          >
            {
              console.log('booking?.origin?.isSPPicked && ', {isSPPicked: booking, directionsResponse})
            }
            {/* Commented A , B Directions , of no use for now */}
            {/* {booking?.origin?.isSPPicked && directionsResponse && (
              <DirectionsRenderer options={directionsRendererOptions} />
            )} */}
             {booking?.origin?.isSPPicked && directionsResponse2 && (
              <DirectionsRenderer options={directionsRendererOptions2} />
            )}
            {filteredDrivers &&
              shouldShowTaxi &&
              filteredDrivers.map((driver) => {
                const coords = {
                  lat: parseFloat(driver.location.coordinates[1]),
                  lng: parseFloat(driver.location.coordinates[0]),
                };
                return (
                  <Marker
                    key={driver._id}
                    position={coords}
                    icon={markerIcon(driver)}
                    onClick={() => setSelectedDriver(driver)}
                  />
                );
              })}

            {/* {position && ( // Current location marker 
              <Marker
                position={{
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                }}
              />
            )} */}

            {emergencyRequests && (
              <Drivers
                setSelectedEmergencyReq={setSelectedEmergencyReq}
                selectedEmergencyReq={selectedEmergencyReq}
                emergencyRequests={emergencyRequests}
              />
            )}
          </GoogleMap>
        </Box>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {/* Filters */}
              {t("filter.filter")}
            </ModalHeader>{" "}
            <ModalCloseButton />
            <ModalBody>
              <VStack
                bgColor="white"
                padding={4}
                borderRadius="lg"
                zIndex="modal"
                spacing={{ base: 2, md: 8 }}
              >
                <FormControl>
                  <FormLabel fontSize={{ base: "sm", md: "md" }}>
                    {/* Taxi type */}
                    {t("filter.taxiType")}
                  </FormLabel>
                  <Select
                    onChange={(e) => setVehicleType(e.target.value)}
                    defaultValue={vehicleType}
                  >
                    <option value="ALL">{t("filter.all")}</option>
                    <option value={VEHICLE_TYPE.TAXI}>Taxi</option>
                    <option value={VEHICLE_TYPE.EXECUTIVE_TAXI}>Premium</option>
                    {/* {Object.values(VEHICLE_TYPE).map(item => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))} */}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={{ base: "sm", md: "md" }}>
                    {/* Minimum Passengers */}
                    {t("filter.minimumPassengers")}
                  </FormLabel>
                  <Input
                    type="number"
                    name="passengers"
                    id="passengers"
                    value={passengerCapacity}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setPassengerCapacity("");
                        return;
                      }
                      setPassengerCapacity(parseInt(e.target.value));
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={{ base: "sm", md: "md" }}>
                    {/* Accepts credit card */}
                    {t("filter.acceptCreditCard")}
                  </FormLabel>
                  <RadioGroup
                    value={acceptCreditCard}
                    onChange={setAcceptCreditCard}
                  >
                    <Stack direction="row">
                      {/* <Radio value='1'>Yes</Radio>
                    <Radio value='0'>No</Radio>
                    <Radio value='2'>Both</Radio> */}
                      <Radio value="1">{t("filter.yes")}</Radio>
                      <Radio value="0">{t("filter.no")}</Radio>
                      <Radio value="2">{t("filter.all")}</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={{ base: "sm", md: "md" }}>
                    {/* Gender */}
                    {t("filter.gender")}
                  </FormLabel>
                  <RadioGroup value={gender} onChange={setGender}>
                    <Stack direction="row">
                      <Radio value={DRIVER_GENDER.all}>
                        {/* {DRIVER_GENDER.all} */}
                        {t("filter.all")}
                      </Radio>
                      <Radio value={DRIVER_GENDER.male}>
                        {/* {DRIVER_GENDER.male} */}
                        {t("filter.male")}
                      </Radio>
                      <Radio value={DRIVER_GENDER.female}>
                        {/* {DRIVER_GENDER.female} */}
                        {t("filter.female")}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <Checkbox
                  size="lg"
                  alignSelf="flex-start"
                  onChange={(e) => setSpeaksEnglish(e.target.checked)}
                >
                  {t("filter.englishSpoken")}
                </Checkbox>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  setAcceptCreditCard("2");
                  setPassengerCapacity("");
                  setVehicleType("ALL");
                  setGender(DRIVER_GENDER.all);
                }}
              >
                {/* Clear filter */}
                {t("filter.clearFilter")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <DriverDetails
          driver={selectedDriver}
          fare={fare}
          distance={distanceText}
          duration={durationText}
          isOpen={selectedDriver}
          onClose={() => setSelectedDriver(null)}
        />
      </VStack>
    </>
  );
}
