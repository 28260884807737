import { Box,  FormControl as ChakraFormControl,  Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export default function ExpandedEmergencyDetails({ data }) {
  const [distance, setDistance] = useState(null);

  useEffect(() => {
    const calculateDistance = () => {
        if (data.locationCoordinates && data.destinationCoordinates) {

            const lat1 = data.locationCoordinates.coordinates[0]
            const lon1 = data.locationCoordinates.coordinates[1]
            const lat2 = data.destinationCoordinates.coordinates[0]
            const lon2 = data.destinationCoordinates.coordinates[1]

            const R = 6371; // Radius of the earth in km
            const dLat = (lat2 - lat1) * (Math.PI / 180); // Convert degrees to radians
            const dLon = (lon2 - lon1) * (Math.PI / 180);
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const distance = R * c; // Distance in km
            setDistance(distance.toFixed(2)); // Round to two decimal places
        }
    };

    calculateDistance();
  }, [data.locationCoordinates, data.destinationCoordinates]);

  return (
    <Box>
      <Table variant="simple" colorScheme="gray">
        <Tbody style={{width:"40%"}} >
          <Tr>
            <Td fontSize="sm" width="15%">Nombre y Apellido</Td>
            <Td fontSize="sm">{data.name}</Td>
          </Tr>
          <Tr>
            <Td fontSize="sm" width="15%">Ingrese su RUT</Td>
            <Td fontSize="sm">{data.rutNumber}</Td>
          </Tr>
          <Tr>
            <Td fontSize="sm" width="15%">mail@dominio.cl</Td>
            <Td fontSize="sm">{data.email}</Td>
          </Tr>
          <Tr>
            <Td fontSize="sm" width="15%">Teléfono</Td>
            <Td fontSize="sm">{data.phoneNumber}</Td>
          </Tr>

          <Tr>
            <Td fontSize="sm" width="15%">Tipo de vehículo</Td>
            <Td fontSize="sm">{data.vehicleType}</Td>
          </Tr>
          <Tr>
            <Td fontSize="sm" width="15%">Marca</Td>
            <Td fontSize="sm">{data.vehicleBrand}</Td>
          </Tr>
          <Tr>
            <Td fontSize="sm" width="15%">Modelo</Td>
            <Td fontSize="sm">{data.vehicleModel}</Td>
          </Tr>
          <Tr>
            <Td fontSize="sm" width="15%">Color</Td>
            <Td fontSize="sm">{data.vehicleColor}</Td>
          </Tr>

          <Tr>
            <Td fontSize="sm" width="15%">Año</Td>
            <Td fontSize="sm">{data.vehicleYear}</Td>
          </Tr>
          <Tr>
            <Td fontSize="sm" width="15%">Patente</Td>
            <Td fontSize="sm">{data.vehicleNumber}</Td>
          </Tr>
          <Tr>
            <Td fontSize="sm" width="15%">El problema</Td>
            <Td fontSize="sm">{data.vehicleIssue}</Td>
          </Tr>
          <Tr>
            <Td fontSize="sm" width="15%">Origen</Td>
            <Td fontSize="sm">{data.location}</Td>
          </Tr>
          <Tr>
            <Td fontSize="sm" width="15%">Destino</Td>
            <Td fontSize="sm">{data.destination}</Td>
          </Tr>
          <Tr>
            <Td fontSize="sm" width="15%">Distancia Km</Td>
            <Td fontSize="sm">{distance}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
}
