import { useToast } from "@chakra-ui/react";
import constant from "../constant";
import axiosInstance from "../interceptor";

const useAdmin = () => {
  const toast = useToast();

  const createAdmin = async (payload) => {
    try {
      const res = await axiosInstance.post(
        `${constant.BASE_URL_ADMIN}/organization-user`,
        payload,
        // {
        //   headers: {
        //     Authorization: "Bearer " + localStorage.getItem("access_token"),
        //   },
        // }
      );
      if (res) {
        toast({
          description: "Monitor created successfully!",
          duration: 2000,
          isClosable: true,
        });
        window.location.reload();
      }
    } catch (e) {
      console.log("Error in submitting form", e);
    }
  };

  const getAllAdmins = async (payload) => {
    try {
      const res = await axiosInstance.get(
        `${constant.BASE_URL_ADMIN}/organization-user/all`,
        payload,
      );
      if (res) {
        toast({
          description: "Monitors retrieved successfully!",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (e) {
      console.log("Error in submitting form", e);
    }
  };

  const updateAdmin = async (payload) => {
    try {
      const res = await axiosInstance.put(
        `${constant.BASE_URL_ADMIN}/organization-user`,
        payload,
        // {
        //   headers: {
        //     Authorization: "Bearer " + localStorage.getItem("access_token"),
        //   },
        // }
      );
      if (res) {
        toast({
          description: "Monitor updated successfully!",
          duration: 2000,
          isClosable: true,
        });
        window.location.reload();
      }
    } catch (e) {
      console.log("Error in submitting form", e);
    }
  };

  const deleteAdmin = async (adminId) => {
    try {
      const res = await axiosInstance.delete(
        `${constant.BASE_URL_ADMIN}/organization-user/${adminId}`,
        // {
        //   headers: {
        //     Authorization: "Bearer " + localStorage.getItem("access_token"),
        //   },
        // }
      );
      if (res) {
        toast({
          description: "Monitor deleted successfully!",
          duration: 2000,
          isClosable: true,
        });
        window.location.reload();
      }
    } catch (e) {
      console.log("Error in submitting form", e);
    }
  };

  return {
    createAdmin,
    getAllAdmins,
    updateAdmin,
    deleteAdmin,
  };
};

export default useAdmin;
