import { Box, Img } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ".././main.scss";
import "./index.scss";
import CustomModal from "../Modals/CustomModal";
import ContactListModal from "../Modals/ContactListModal";
import CordinatesModal from "../Modals/CordinatesModal";
import EmergencyList from "../Emergencies";
import { Link } from "react-router-dom";
import logo8080 from "../../assets/images/8080-logo.jpeg";

function LeftHome() {
  const [openModal, setOpenModal] = useState(false);
  const [isShowCordinatesModal, setIsShowCordinatesModal] = useState(false);
  const [isShowContactList, setIsShowContactList] = useState(false);
  const [requestId, setRequestId] = useState(null);

  return (
    <Box>
      <Box height={16} padding={3}>
        {/* please remove Inline styles */}
        <Box className="row">
          <Img src={logo8080} height="42px" />
        </Box>
      </Box>

      <Box
        style={{
          padding: 4,
          backgroundColor:
            window.location.pathname === "/user"
              ? "rgb(191 238 239)"
              : "#f9fafb",
        }}
      >
        <div style={{ height: "96vh", overflowY: "scroll" }}>
          {window.location.pathname === "/user" ? (
            <EmergencyList />
          ) : (
            <>
              <Box marginLeft={4} marginY={10}>
                <Link to="/drivers">Choferes</Link>
              </Box>
              <Box marginLeft={4} marginY={10}>
                <Link to="/emergencies">Emergencias</Link>
              </Box>
              <Box marginLeft={4} marginY={10}>
                <Link to="/users">Usuarios</Link>
              </Box>
            </>
          )}
        </div>
        {/* <CordinatesModal
          openModal={isShowCordinatesModal}
          setOpenModal={setIsShowCordinatesModal}
        />
        <ContactListModal
          isShowContactList={isShowContactList}
          setIsShowContactList={setIsShowContactList}
        /> */}
      </Box>
    </Box>
  );
}

export default LeftHome;
