import {
  Box,
  ButtonGroup,
  Container,
  Divider,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { InputControl, ResetButton, SubmitButton } from "formik-chakra-ui";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Card } from "../../components/Card";
import useAuthentication from "../../hooks/useAuthentication";
// import ForgotPasswordModal from '../../components/ForgotPasswordModal'

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { requestLinkForForgotPassword } = useAuthentication();

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  const navigateToLogin = () => {
    navigate("login");
  };

  return (
    <Container>
      <Heading textAlign="center" my={12}>
        Forgot Password
      </Heading>
      <Card maxW="md" mx="auto" mt={4}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={requestLinkForForgotPassword}
        >
          <Form>
            <VStack alignItems="flex-start" spacing={6}>
           
              <InputControl name="email" label="Email" id="email" />
              
              <ButtonGroup>
                <SubmitButton>Send Link</SubmitButton>
                {/* <ResetButton colorScheme='primary'>Reset</ResetButton> */}
              </ButtonGroup>
              <Divider />
              <Box alignItems="center">
                <Text
                  fontSize="sm"
                  as="u"
                  textColor={"blue.900"}
                  style={{ cursor: "pointer" }}
                  onClick={navigateToLogin}
                >
                  Back to Login
                </Text>
              </Box>
            </VStack>
          </Form>
        </Formik>
      </Card>
    </Container>
  );
}
