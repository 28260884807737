import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useSWR from "swr";
import constant from "../constant";
import axiosInstance from "../interceptor";
import { getHostname } from "../utils/domainName"; // Import the utility function

const useDriver = () => {
  const toast = useToast();
  const [numericPart, setNumericPart] = useState(null); // Initialize numericPart with useState

  useEffect(() => {
    const numeric = getHostname(); // Call the utility function to get the numeric part
    setNumericPart(numeric); // Set numericPart using useState
    console.log("Numeric part of hostname in AnotherComponent:", numeric);
  }, []);

  const fetcher = (url) => axiosInstance.get(url).then((res) => res.data);
  const { data, error: apiError } = useSWR(
    `${constant.BASE_URL_CLIENT}/near-me-drivers-detail?lng=${JSON.parse(localStorage.getItem("longitude"))}&lat=${JSON.parse(localStorage.getItem("latitude"))}&sub=${JSON.parse(numericPart)}`,
    fetcher
  );

  const [drivers, setDrivers] = useState([])

  useEffect(() => { setDrivers(data) }, [data])

  const createDriver = async (payload) => {
    try {
      const res = await axiosInstance.post(
        `${constant.BASE_URL_ADMIN}/driver`,
        payload
        // {
        //   headers: {
        //     Authorization: "Bearer " + localStorage.getItem("access_token"),
        //   },
        // }
      );
      if (res) {
        toast({
          description: "Driver created successfully!",
          duration: 2000,
          isClosable: true,
        });
        window.location.reload()
      }
    } catch (e) {
      console.log("Error in submitting form", e);
    }
  };

  const getAllDrivers = async (payload) => {
    try {
      const res = await axiosInstance.get(
        `${constant.BASE_URL_CLIENT}/near-me-drivers-detail?lng=${JSON.parse(localStorage.getItem("longitude"))}&lat=${JSON.parse(localStorage.getItem("latitude"))}&sub=${JSON.parse(numericPart)}`,
      );
      setDrivers(res.data)
    } catch (e) {
      console.log("Error in submitting form", e);
    }
  };

  const updateDriver = async (payload) => {
    try {
      const res = await axiosInstance.put(
        `${constant.BASE_URL_ADMIN}/driver`,
        payload
        // {
        //   headers: {
        //     Authorization: "Bearer " + localStorage.getItem("access_token"),
        //   },
        // }
      );
      if (res) {
        toast({
          description: "Driver updated successfully!",
          duration: 2000,
          isClosable: true,
        });
        window.location.reload()
      }
    } catch (e) {
      console.log("Error in submitting form", e);
    }
  };

  const deleteDriver = async (driverId) => {
    console.log('driverId', driverId)
    try {
      const res = await axiosInstance.delete(
        `${constant.BASE_URL_ADMIN}/driver/${driverId}?useId=true`
        // {
        //   headers: {
        //     Authorization: "Bearer " + localStorage.getItem("access_token"),
        //   },
        // }
      );
      if (res) {
        toast({
          description: "Driver deleted successfully!",
          duration: 2000,
          isClosable: true,
        });
        window.location.reload()
      }
    } catch (e) {
      console.log("Error in submitting form", e);
    }
  };

  return {
    drivers,
    createDriver,
    getAllDrivers,
    updateDriver,
    deleteDriver
  };
};

export default useDriver;