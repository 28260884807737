export const DRIVER_VERIFICATION_STATUS = {
  approved: "APPROVED",
  pending: "PENDING",
  declined: "DECLINED",
  blocked: "BLOCKED",
};

export const getBadgeColor = (verificationStatus) => {
  switch (verificationStatus) {
    case DRIVER_VERIFICATION_STATUS.approved:
      return "green";
    case DRIVER_VERIFICATION_STATUS.blocked:
      return "red";
    case DRIVER_VERIFICATION_STATUS.active:
      return "orange";
    case DRIVER_VERIFICATION_STATUS.dispatched:
      return "blue";
    default:
      return "gray";
  }
};

export const TRIP_STATUS = {
  pending: "PENDING",
  accepted: "ACCEPTED",
  declined: "DECLINED",
  started: "STARTED",
  completed: "COMPLETED",
  cancelled: "CANCELLED",
   // NEW STATUSES 
   active: 'ACTIVE',
   dispatched: 'DISPATCHED',
   solved: 'SOLVED',
   hidden: 'HIDDEN'
};

export const getTripStatusBadgeColor = (status) => {
  switch (status) {
    case TRIP_STATUS.accepted:
      return "yellow";
    case TRIP_STATUS.started:
      return "blue";
    case TRIP_STATUS.completed:
      return "green";
    case TRIP_STATUS.active:
      return "orange";
    case TRIP_STATUS.cancelled:
      return "blackAlpha";
    case TRIP_STATUS.declined:
      return "red";
    default:
      return "gray";
  }
};

export const VEHICLE_TYPE = {
  TAXI: "TAXI",
  EXECUTIVE_TAXI: "EXECUTIVE_TAXI",
  AMBULANCE: "AMBULANCE",
  BOMBEROS: "BOMBEROS",
  DOCTOR: "DOCTOR",
  CARABINEROS: "CARABINEROS",
  GRUA: "GRUA",
  MECANICO: "MECANICO",
  PATROL: "PATROL",
  PATROL1: "PATROL1",
  PROMO: "PROMO",
  REDCROSS: "REDCROSS",
};

export const DRIVER_GENDER = {
  male: "MALE",
  female: "FEMALE",
  all: "ALL",
};
