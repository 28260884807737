import { createContext, useContext } from "react";
import useEmergencyRequest from "../hooks/useEmergencyRequest";

const EmergencyReqContext = createContext({});

export const useEmergencyReq = () => useContext(EmergencyReqContext);

export default function EmergencyReqContextProvider({ children }) {
  const { emergencyRequests } = useEmergencyRequest({ isShowAll: false });
  return (
    <EmergencyReqContext.Provider value={emergencyRequests}>
      {children}
    </EmergencyReqContext.Provider>
  );
}
