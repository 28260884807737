import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  FormControl as ChakraFormControl,
  FormLabel,
  HStack,
  IconButton,
  Select,
  SimpleGrid,
  SkeletonText,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import {
  InputControl,
  SelectControl,
  SubmitButton,
} from 'formik-chakra-ui';
import { capitalize, startCase } from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { FaCheck, FaEdit, FaTimes } from 'react-icons/fa';
import useSWR, { mutate } from 'swr';
import * as Yup from 'yup';
import constant from '../../constant';
import API from '../../lib/api-client';
import { DRIVER_VERIFICATION_STATUS, getBadgeColor } from '../../lib/config';
import DriverImageModal from '../Modals/DriverImageModal';

export default function ExpandedComponent({ data }) {
  const {
    _id,
    uid,
    phoneNumber,
    phoneVerified,
    fullName,
    gender,
    speaksEnglish,
    postalAddress,
    personalId,
    drivingLicenseNumber,
    drivingLicenseValidUntil,
    acceptCreditCard,
    images: driverImages,
    vehicle,
    verificationStatus,
    isAvailable,
    location,
    organization,
  } = data;

  console.log("data", data);
  const {
    brand,
    year,
    type,
    registrationNumber,
    registrationValidUntil,
    insuranceValidUntil,
    passengerCapacity,
    images: vehicleImages,
  } = vehicle;

  const images = [
    driverImages?.profile?.url,
    driverImages?.personalId?.url,
    driverImages?.drivingLicense?.url,
    vehicleImages?.vehicle?.url,
    vehicleImages?.registration?.url,
    vehicleImages?.insurance?.url,
  ];

  const { onToggle, isOpen, onClose, onOpen } = useDisclosure()
  const [isEditing, setIsEditing] = useState(false)

  return (
    <Box>
      <Table variant="simple" colorScheme="gray">
        <Tbody>
          <Tr>
            <Td>Database ID & Firebase UID</Td>
            <Td>
              {_id} - {uid}
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight="medium">Personal Details</Td>
          </Tr>
          <Tr>
            <Td>Verification Status</Td>
            <Td>
              <HStack>
                {/* <AnimatePresence exitBeforeEnter> */}
                {isEditing && <UpdateVerificationStatus data={data} />}
                {/* </AnimatePresence> */}
                {!isEditing && (
                  <Badge colorScheme={getBadgeColor(verificationStatus)}>
                    {verificationStatus}
                  </Badge>
                )}
                <IconButton
                  ml={4}
                  isRound
                  colorScheme={isEditing ? "orange" : "green"}
                  aria-label="edit verification status"
                  icon={isEditing ? <FaTimes /> : <FaEdit />}
                  onClick={() => setIsEditing(isEditing ? false : true)}
                />
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>Name</Td>
            <Td>{fullName}</Td>
          </Tr>
          <Tr>
            <Td>Phone Number</Td>
            <Td>{phoneNumber}</Td>
          </Tr>
          <Tr>
            <Td>Gender</Td>
            <Td>{capitalize(gender)}</Td>
          </Tr>
          <Tr>
            <Td>Speaks English</Td>
            <Td>
              {speaksEnglish ? (
                <FaCheck color="green" />
              ) : (
                <FaTimes color="red" />
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>Postal Address</Td>
            <Td>
              <Text>{postalAddress.street}</Text>
              <Text>{postalAddress.houseNumber}</Text>
              <Text>{postalAddress.neighbourhood}</Text>
              <Text>{postalAddress.city}</Text>
              <Text>{postalAddress.postalCode}</Text>
              <Text>{postalAddress.country}</Text>
            </Td>
          </Tr>
          <Tr>
            <Td>Personal Id</Td>
            <Td>{personalId}</Td>
          </Tr>
          <Tr>
            <Td>Driving License Number</Td>
            <Td>{drivingLicenseNumber}</Td>
          </Tr>
          <Tr>
            <Td>Driving LicenseValid Until</Td>
            <Td>
              {DateTime.fromISO(drivingLicenseValidUntil).toLocaleString(
                DateTime.DATE_MED,
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>acceptCreditCard</Td>
            <Td>
              <Badge colorScheme={acceptCreditCard ? "green" : "red"}>
                {acceptCreditCard ? "Accepted" : "Not Accepted"}
              </Badge>
            </Td>
          </Tr>
          <Tr fontWeight="medium">
            <Td>Vehicle</Td>
          </Tr>
          <Tr>
            <Td>Brand</Td>
            <Td>{brand}</Td>
          </Tr>
          <Tr>
            <Td>Year</Td>
            <Td>{year}</Td>
          </Tr>
          <Tr>
            <Td>Type</Td>
            <Td>{capitalize(startCase(type))}</Td>
          </Tr>
          <Tr>
            <Td>Registration Number</Td>
            <Td>{registrationNumber}</Td>
          </Tr>
          <Tr>
            <Td>Registration Valid Until</Td>
            <Td>
              {DateTime.fromISO(registrationValidUntil).toLocaleString(
                DateTime.DATE_MED,
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>Insurance Valid Until</Td>
            <Td>
              {DateTime.fromISO(insuranceValidUntil).toLocaleString(
                DateTime.DATE_MED,
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>Passenger Capacity</Td>
            <Td>{passengerCapacity}</Td>
          </Tr>
          {/* {data.subscription && (
            <Tr>
              <Td>Subscription</Td>
              <Td>
                {data.subscription.status && (
                  <Text>Status: {data.subscription.status}</Text>
                )}
                {data.subscription.transactionId && (
                  <Text>Transactionid: {data.subscription.transactionId}</Text>
                )}
                <Text>Validityindays: {data.subscription.validityInDays}</Text>
                <Text>
                  Start date:{" "}
                  {DateTime.fromISO(data.subscription.startDate).toLocaleString(
                    DateTime.DATE_MED,
                  )}
                </Text>
                <Text>
                  End date:{" "}
                  {DateTime.fromISO(data.subscription.endDate).toLocaleString(
                    DateTime.DATE_MED,
                  )}
                </Text>
              </Td>
            </Tr>
          )} */}

          <Tr fontWeight="medium">
            <Td>Images</Td>
            <Td>
              <Button variant="ghost" onClick={onOpen}>
                View Images
              </Button>
            </Td>
          </Tr>
          {/* <Tr>
            <Td>Organization</Td>
            <Td>{<OrgnizationDetails driver={data} />}</Td>
          </Tr> */}
        </Tbody>
      </Table>

        <DriverImageModal openModal={isOpen}  onClose={onClose} images={images}/>
    </Box>
  );
}

function UpdateVerificationStatus({ data }) {
  const initialValues = {
    verificationStatus: data.verificationStatus,
    adminMessage: data.adminMessage,
  };
  const validationSchema = Yup.object({
    verificationStatus: Yup.string()
      .oneOf(Object.values(DRIVER_VERIFICATION_STATUS))
      .required(),
    adminMessage: Yup.string(),
  });
  function onSubmit(values, { setSubmitting }) {
    if (
      values.verificationStatus === data.verificationStatus &&
      values.adminMessage === data.adminMessage
    ) {
      setSubmitting(false);
      return;
    }
    API.patch(`${constant.BASE_URL_ADMIN}/driver/${data._id}`, values)
      .then((response) => {
        console.log(response);
        setSubmitting(false);
        mutate("/driver");
      })
      .catch((error) => {
        console.log(error);
        setSubmitting(true);
      });
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <HStack spacing={4}>
          <SelectControl name="verificationStatus">
            {Object.values(DRIVER_VERIFICATION_STATUS).map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SelectControl>
          <InputControl
            name="adminMessage"
            inputProps={{
              placeholder: "Optional message",
            }}
          />
          <ButtonGroup>
            <SubmitButton>Update</SubmitButton>
          </ButtonGroup>
        </HStack>
      </Form>
    </Formik>
  );
}

function OrgnizationDetails({ driver }) {
  // Get organization details
  const { data = {}, error } = useSWR(
    driver.organization ? `/organization/${driver.organization}` : null,
  );
  // const { data, error } = useSWR(`/organization/629108388c191ea4ec490739`)

  console.log("org", data);

  if (error) {
    return <Text textColor="red.500">{error.message}</Text>;
  }

  if (!data) {
    return <SkeletonText />;
  }

  // if (driver.organization === '') {
  //   return <Text>Does not belong to any organization</Text>
  // }

  return (
    <VStack spacing={4} alignItems="flex-start">
      {driver.organization === "" ? (
        <Text fontWeight="bold">Does not belong to any organization</Text>
      ) : (
        <SimpleGrid columns={2} spacing={2}>
          <Text>Name</Text>
          <Text>{data.name}</Text>
          <Text>Email</Text>
          <Text>{data.email}</Text>
          <Text>Phone Number</Text>
          <Text>{data.phoneNumber}</Text>
        </SimpleGrid>
      )}
      <OrganizationSelectControl driver={driver} />
    </VStack>
  );
}

function OrganizationSelectControl({ driver }) {
  const { data: organizations, error } = useSWR("/organization");

  if (error) {
    if (error.status === 403) {
      return <></>;
    }
    return <Text textColor="red.500">{error.message}</Text>;
  }

  if (!organizations) {
    return <SkeletonText />;
  }

  function handleChange(e) {
    console.log("e.target.value", e.target.value, e.target.value.length);
    const driverId = driver._id;
    const organizationId = e.target.value;

    API.post("/organization/add-driver-to-organization", {
      driverId,
      organizationId,
    })
      .then((response) => {
        mutate(`/driver`);
        console.log("response", response);
      })
      .catch((error) => console.log(error.message));
  }

  return (
    <ChakraFormControl>
      <FormLabel>Select or remove organization</FormLabel>
      <Select onChange={handleChange} defaultValue={driver.organization}>
        <option value="">Remove organization</option>
        {organizations.map((organization) => (
          <option value={organization._id} key={organization._id}>
            {organization.name} ({organization.email},{" "}
            {organization.phoneNumber})
          </option>
        ))}
      </Select>
    </ChakraFormControl>
  );
}
