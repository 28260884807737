import { Box, Button, FormControl, FormLabel, Grid, GridItem, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, TableContainer, Text, VStack, chakra, useMediaQuery } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import constant from "../../constant";
import { useEmergencyReqDetail } from "../../contexts/EmergencyReqDetailContext";
import { sendContactInformation } from "../../helpers";
import axiosInstance from "../../interceptor";

const INITIAL_CONTACT_VALUES = {
  whoCalled: "",
  phoneNumber: "",
  gpsPosition: "",
  problem: "",
  vehicle: "",
  color: "",
  observations: "",
};

const CustomModal = ({ openModal, setOpenModal, requestId, data }) => {
  const [isMobile] = useMediaQuery("(max-width: 425px)");
  const [isGruaType, setIsGruaType] = useState(false);
  const [contactValues, setContactValues] = useState(INITIAL_CONTACT_VALUES);
  const [distance, setDistance] = useState(null);
  const [showSPMapUsingEmergencyId, setShowSPMapUsingEmergencyId] = useState(null);
  const [selectedEmergencyReq, setSelectedEmergencyReq] = useState(null);
  const { t } = useTranslation();
  const { isShowEmergencyReqModal, selectedEmergencyReqFromContext, onOpen, onSendingSP } = useEmergencyReqDetail();

  useEffect(() => {
    if (isShowEmergencyReqModal) {
      setSelectedEmergencyReq(selectedEmergencyReqFromContext);
    }
  }, [selectedEmergencyReqFromContext]);

  useEffect(() => {
    if (selectedEmergencyReqFromContext && Object.keys(selectedEmergencyReqFromContext).length > 0) {
      setIsGruaType(selectedEmergencyReqFromContext?.rutNumber);
      setContactValues({
        ...INITIAL_CONTACT_VALUES,
        whoCalled: selectedEmergencyReqFromContext.name,
        phoneNumber: selectedEmergencyReqFromContext.phoneNumber,
        gpsPosition: selectedEmergencyReqFromContext.location,
        rutNumber: selectedEmergencyReqFromContext.rutNumber,
        ...selectedEmergencyReqFromContext,
      });
    } else {
      setContactValues(INITIAL_CONTACT_VALUES);
    }
  }, [selectedEmergencyReqFromContext]);

  useEffect(() => {
    (async function () {
      if (requestId) {
        const response = await axiosInstance.get(`${constant.BASE_URL_ADMIN}/contact/request/${requestId}`);
        if (response.data.contactAction) {
          // setContactValues({
          //   ...INITIAL_CONTACT_VALUES,
          //   whoCalled: selectedEmergencyReqFromContext.name,
          //   phoneNumber: selectedEmergencyReqFromContext.phoneNumber,
          //   gpsPosition: selectedEmergencyReqFromContext.location,
          //   rutNumber: selectedEmergencyReqFromContext.rutNumber,
          //   ...selectedEmergencyReqFromContext,
          //   ...response.data.contactAction,
          // });
          setContactValues({ ...response.data.contact, ...response.data.contactAction });
        }
      }
    })();
  }, [requestId]);

  useEffect(() => {
    const calculateDistance = () => {
      if (data.locationCoordinates && data.destinationCoordinates) {
        const lat1 = data.locationCoordinates.coordinates[0];
        const lon1 = data.locationCoordinates.coordinates[1];
        const lat2 = data.destinationCoordinates.coordinates[0];
        const lon2 = data.destinationCoordinates.coordinates[1];

        const R = 6371; // Radius of the earth in km
        const dLat = (lat2 - lat1) * (Math.PI / 180); // Convert degrees to radians
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in km
        setDistance(distance.toFixed(2)); // Round to two decimal places
      }
    };

    calculateDistance();
  }, [data.locationCoordinates, data.destinationCoordinates]);

  const onClose = () => {
    setOpenModal(false);
  };

  const handleInputs = (event) => {
    const { name, value } = event.target;
    const _contactValues = { ...contactValues };
    _contactValues[name] = value;
    console.log({ _contactValues });
    setContactValues(_contactValues);
  };

  const handleSubmitInputs = () => {
    sendContactInformation({ requestId, ...contactValues }, t("helperindex.Detailsupdatedby"));
    setOpenModal(false);
  };

  return (
    <Modal isOpen={openModal} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Box className="modal-open">
            <h2>Adquisición de los datos</h2>
            <TableContainer>
              {isGruaType ? (
                <chakra.form
                // onSubmit={onSubmit}
                >
                  <VStack alignItems="stretch" spacing={4}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "space-between" }}>
                      <FormControl marginRight={4}>
                        <FormLabel htmlFor="name">Nombre y Apellido</FormLabel>
                        <Input name="name" value={contactValues.name} type="text" isRequired onChange={handleInputs} />
                      </FormControl>
                      <FormControl marginRight={4}>
                        <FormLabel htmlFor="name">RUT</FormLabel>
                        <Input name="idNumber" value={contactValues.rutNumber} type="text" isRequired onChange={handleInputs} />
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="name">Teléfono</FormLabel>
                        <Input name="phoneNumber" type="text" value={contactValues.phoneNumber} onChange={handleInputs} />
                      </FormControl>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "space-between" }}>
                      <FormControl marginRight={4}>
                        <FormLabel htmlFor="name">mail@dominio.cl</FormLabel>
                        <Input name="email" value={contactValues.email} type="text" isRequired onChange={handleInputs} />
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="name">Presupuesto</FormLabel>
                        <Input name="priceQuotation" value={contactValues.priceQuotation} type="text" onChange={handleInputs} />
                      </FormControl>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "space-between" }}>
                      <FormControl marginRight={4}>
                        <FormLabel htmlFor="name">Tipo de vehículo</FormLabel>
                        <Input name="vehicleType" value={contactValues.vehicleType} type="text" onChange={handleInputs} />
                      </FormControl>
                      <FormControl marginRight={4}>
                        <FormLabel htmlFor="name">Marca</FormLabel>
                        <Input name="vehicleBrand" type="text" value={contactValues.vehicleBrand} onChange={handleInputs} />
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="name">Modelo</FormLabel>
                        <Input name="vehicleModel" type="text" value={contactValues.vehicleModel} onChange={handleInputs} />
                      </FormControl>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "space-between" }}>
                      <FormControl marginRight={4}>
                        <FormLabel htmlFor="name">Color</FormLabel>
                        <Input name="vehicleColor" type="text" value={contactValues.vehicleColor} onChange={handleInputs} />
                      </FormControl>
                      <FormControl marginRight={4} width="35%">
                        <FormLabel htmlFor="name">Año</FormLabel>
                        <Input name="vehicleYear" type="text" value={contactValues.vehicleYear} onChange={handleInputs} />
                      </FormControl>
                      <FormControl marginRight={4} width="35%">
                        <FormLabel htmlFor="name">Patente</FormLabel>
                        <Input name="vehicleNumber" type="text" value={contactValues.vehicleNumber} onChange={handleInputs} />
                      </FormControl>
                      {/* <FormControl>
                        <FormLabel htmlFor="name">Asegurador</FormLabel>
                        <Input name="insuranceCompany" type="text" />
                      </FormControl> */}
                    </div>

                    <FormControl>
                      <FormLabel htmlFor="name">El problema</FormLabel>
                      <Input name="vehicleIssue" type="text" value={contactValues.vehicleIssue} onChange={handleInputs} />
                    </FormControl>

                    {/* <div style={{ display: "flex", flexDirection: "row", alignItems: "space-between" }}> */}
                    <FormControl marginRight={4}>
                      <FormLabel htmlFor="name">Origen</FormLabel>
                      <Input name="location" type="text" value={contactValues.location} onChange={handleInputs} />
                    </FormControl>
                    <FormControl marginRight={4}>
                      <FormLabel htmlFor="name">Información de tu póliza de seguro</FormLabel>
                      <Input name="insuranceInfo" type="text" value={contactValues.insuranceInfo} onChange={handleInputs} />
                    </FormControl>
                    <FormControl marginRight={4}>
                      <FormLabel htmlFor="name">Destino</FormLabel>
                      <Input name="destination" type="text" value={contactValues.destination} onChange={handleInputs} />
                    </FormControl>
                    <FormControl marginRight={4}>
                      <FormLabel htmlFor="name">Distancia Km</FormLabel>
                      <Input name="distanceKM" type="text" value={distance} onChange={handleInputs} />
                    </FormControl>
                    {/* </div> */}
                  </VStack>
                </chakra.form>
              ) : (
                <>
                  <Grid templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={isMobile ? 1 : 2} style={{ marginBottom: 10 }}>
                    <GridItem w="100%">
                      <Text className="inputColor">Nombre</Text>
                    </GridItem>
                    <GridItem w="100%">
                      <Input name="whoCalled" value={contactValues.whoCalled} onChange={handleInputs} />
                    </GridItem>
                  </Grid>

                  <Grid templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={isMobile ? 1 : 2} style={{ marginBottom: 10 }}>
                    <GridItem w="80%">
                      <Text className="inputColor">Teléfono</Text>
                    </GridItem>
                    <GridItem w="80%">
                      <Input name="phoneNumber" value={contactValues.phoneNumber} onChange={handleInputs} />
                    </GridItem>
                  </Grid>

                  <Grid templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={isMobile ? 1 : 2} style={{ marginBottom: 10 }}>
                    <GridItem w="80%">
                      <Text className="inputColor">Donde</Text>
                    </GridItem>
                    <GridItem w="80%">
                      <Input name="gpsPosition" value={contactValues.gpsPosition} onChange={handleInputs} />
                    </GridItem>
                  </Grid>

                  <Grid templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={isMobile ? 1 : 2} style={{ marginBottom: 10 }}>
                    <GridItem w="80%">
                      <Text className="inputColorSecond">Vehículo</Text>
                    </GridItem>
                    <GridItem w="80%">
                      <Input name="vehicle" value={contactValues.vehicle} onChange={handleInputs} />
                    </GridItem>
                  </Grid>

                  <Grid templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={isMobile ? 1 : 2} style={{ marginBottom: 10 }}>
                    <GridItem w="80%">
                      <Text className="inputColorSecond">Color</Text>
                    </GridItem>
                    <GridItem w="80%">
                      <Input name="color" value={contactValues.color} onChange={handleInputs} />
                    </GridItem>
                  </Grid>

                  <Grid templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={isMobile ? 1 : 2} style={{ marginBottom: 10 }}>
                    <GridItem w="80%">
                      <Text className="inputColor">Emergencia</Text>
                    </GridItem>
                    <GridItem w="80%">
                      <Input name="gpsPosition" value={contactValues.emergencyType} onChange={handleInputs} />
                    </GridItem>
                  </Grid>

                  <Grid templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={isMobile ? 1 : 2} style={{ marginBottom: 10 }}>
                    <GridItem w="100%">
                      <Text className="inputColorSecond">El problema</Text>
                    </GridItem>
                    <GridItem w="100%">
                      <Text name="problem" value={contactValues.problem} onChange={handleInputs} />
                    </GridItem>
                  </Grid>

                  <Grid templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={isMobile ? 1 : 2} style={{ marginBottom: 10 }}>
                    <GridItem w="100%">
                      <Text className="inputColorSecond">Observaciones</Text>
                    </GridItem>
                    <GridItem w="100%">
                      <Text name="observations" value={contactValues.observations} onChange={handleInputs} />
                    </GridItem>
                  </Grid>
                </>
              )}

              <Box style={{ textAlign: "center", marginTop: 20 }}>
                <Button style={{ textTransform: "none" }} _hover={{ bg: "#0099ff" }} onClick={handleSubmitInputs}>
                  Guardar
                </Button>
                <Button
                  colorScheme="primary"
                  marginLeft={2}
                  onClick={() => {
                    onSendingSP(data);
                    setShowSPMapUsingEmergencyId((prev) => (!prev ? data._id : null));
                    setOpenModal(false);
                  }}
                >
                  {showSPMapUsingEmergencyId === data._id ? "Despachar" : "Despachar"}
                </Button>
              </Box>
            </TableContainer>
          </Box>
          <Box my={4} mx={2}>
            {
              // data.logs?.map(log => {
              //   return (
              //     <Text fontSize='sm' fontWeight={600} textColor='#08569F'>{moment(log.createdAt).format('YYYY-MM-DD HH:mm')} {log.desc}</Text>
              //   )
              // })

              data.logs
                ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                ?.map((log) => {
                  const replaceTranslation = (description) => {
                    // Check if the description includes the specific part "Details updated"
                    if (description.includes("Details updated")) {
                      // Replace the common part with the desired translation
                      return description.replace("Details updated", "Detalles actualizados por");
                    } else if (description.includes("Dispatched Ambulance")) {
                      return description.replace("Dispatched Ambulance", "Ambulancia enviada");
                    }
                    // Return the original description if no replacement is needed
                    return description;
                  };

                  return (
                    <Text key={log.id} fontSize="sm" fontWeight={600} textColor="#08569F">
                      {moment(log.createdAt).format("YYYY-MM-DD HH:mm")} {replaceTranslation(log.desc)}
                    </Text>
                  );
                })
            }
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default React.memo(CustomModal);
