import { useDisclosure } from "@chakra-ui/react";
import { useContext, createContext, useState, useEffect } from "react";

const EmergencyReqDetailContext = createContext({});
let emergencyRequest = {};
console.log("CALLED CONTEXT");
export const useEmergencyReqDetail = () =>
  useContext(EmergencyReqDetailContext);

export default function EmergencyReqDetailContextProvider({ children }) {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const [selectedEmergencyReqFromContext, setSelectedEmergencyReqFromContext] = useState(null);
  const [sendSPPayload, setSendSPPayload] = useState(false);
  const [showSPMapUsingEmergencyId, setShowSPMapUsingEmergencyId] = useState(null);

  useEffect(() => {
    setSelectedEmergencyReqFromContext(emergencyRequest);
  }, []);

  const handleOpen = (data) => {
    onOpen();
    emergencyRequest = data;
    console.log("emergencyRequest", emergencyRequest);
    setSelectedEmergencyReqFromContext(data);
  };

  const saveBookingInfo = (emergency) => {
    // console.log("saveBookingInfo in context called", { emergency });
    localStorage.setItem("x-name", JSON.stringify(emergency.name));
    localStorage.setItem("x-request-id", JSON.stringify(emergency._id));
    localStorage.setItem(
      "x-phone-number",
      JSON.stringify(emergency.phoneNumber),
    );
    localStorage.setItem(
      "x-position",
      JSON.stringify({
        coords: {
          latitude: emergency.locationCoordinates.coordinates[0],
          longitude: emergency.locationCoordinates.coordinates[1],
          accuracy: 0,
          altitude: 0,
          altitudeAccuracy: 0,
          heading: 0,
          speed: 0,
        },
        timestamp: Date.now(),
      }),
    );
    setSendSPPayload(() => {
      const _payload = {
        "x-name": emergency.name,
        "x-id": emergency._id,
        "x-phone-number": emergency.phoneNumber,
        "x-position": {
          coords: {
            latitude: emergency.locationCoordinates.coordinates[0],
            longitude: emergency.locationCoordinates.coordinates[1],
            accuracy: 0,
            altitude: 0,
            altitudeAccuracy: 0,
            heading: 0,
            speed: 0,
          },
          timestamp: Date.now(),
        },
      };
      return _payload;
    });
    setShowSPMapUsingEmergencyId((prev) =>
      !prev ? emergency._id : setSendSPPayload({}),
    );
  };

  const onSendingSP = (data) => {
    saveBookingInfo(data);
    // setSendSPPayload(prev => !prev)
  };

  const value = {
    isShowEmergencyReqModal: isOpen,
    onOpen: handleOpen,
    onClose,
    onToggle,
    onSendingSP,
    selectedEmergencyReqFromContext,
    setSelectedEmergencyReqFromContext,
    sendSPPayload,
  };

  return (
    <EmergencyReqDetailContext.Provider value={value}>
      {children}
    </EmergencyReqDetailContext.Provider>
  );
}
