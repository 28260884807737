import {Box,Text,useToast} from "@chakra-ui/react";
import DeleteIcon from "@material-ui/icons/Delete";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import moment from "moment";
import * as React from "react";
import {useEffect,useState} from "react";
import constant from "../../constant";
import {useEmergencyReq} from "../../contexts/EmergencyReqContext";
import {useEmergencyReqDetail} from "../../contexts/EmergencyReqDetailContext";
import CustomModal from "../Modals/CustomModal";
import "./index.scss";
import axiosInstance from "../../interceptor";

const EmergencyList = () => {
  const toast = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [requestId, setRequestId] = useState(null);

  const contactList = useEmergencyReq();
  const {
    isShowEmergencyReqModal,
    selectedEmergencyReqFromContext,
    onOpen,
    onSendingSP,
  } = useEmergencyReqDetail();
  const [selectedEmergencyReq, setSelectedEmergencyReq] = useState(null);
  const [showSPMapUsingEmergencyId, setShowSPMapUsingEmergencyId] =
    useState(null);

  const handleOpenModal = (selectedRequestId) => {
    setOpenModal(true);
    setRequestId(selectedRequestId);
  };

  useEffect(() => {
    if (isShowEmergencyReqModal) {
      setSelectedEmergencyReq(selectedEmergencyReqFromContext);
    }
  }, [selectedEmergencyReqFromContext]);

  // const toggleContactListModal = () => setIsShowContactList(true);
  // const toggleCordinatesModal = () => setIsShowCordinatesModal((prev) => !prev);

  const handleDelete = async (e, reqId) => {
    if (e.detail !== 2) {
      return;
    }
    try {
      const res = await axiosInstance.delete(
        `${constant.BASE_URL_ADMIN}/contact/${reqId}`,
      );
      if (res) {
        toast({
          description: "Emergency deleted successfully!",
          duration: 2000,
          isClosable: true,
        });
        window.location.reload();
      }
    } catch (e) {
      console.log("Error in submitting form", e);
    }
  };

  const Emergency = ({ data }) => (
    <Box>{data?.tripId?.status !== "SOLVED" ?
    <Box
      paddingY={2}
      paddingLeft={4}
      style={{ cursor: "pointer" }}
      className={selectedEmergencyReq?._id === data?._id && "highlight_request"}
      onClick={() => onOpen(data)}
    >
      {/* </Grid> */}
      <Text fontSize={12}>{data.name}</Text>
      <Text fontSize={12}>{data.emergencyType}</Text>
      <Text fontSize={12}>{data.phoneNumber}</Text>
      <Text fontSize={12}>
        {moment(data.created).format("YYYY/MM/DD-HH:mm")}
      </Text>
      <Box marginTop={"2"}>
        <Box>
          <MessageOutlinedIcon
            style={{ fill: "#ff0000", cursor: "pointer", width: "20px" }}
            onClick={() => handleOpenModal(data?._id)}
          />
          {/* <DeleteIcon
            style={{
              fill: "#ff0000",
              cursor: "pointer",
              width: "20px",
              marginLeft: "8px",
            }}
            onClick={(e) => handleDelete(e, data?._id)}
          /> */}
          {/* <Button colorScheme='primary' marginLeft={2} onClick={() => {
            onSendingSP(data);
            setShowSPMapUsingEmergencyId(prev => !prev ? data._id : null)
          }}>{showSPMapUsingEmergencyId === data._id ? 'Undo' : 'Despachar'}</Button> */}
        </Box>
      </Box>
    </Box>: <></>}</Box>
  );
  return (
    <>
      {contactList?.map((emergency) => (
        <div key={emergency._id}>
          <Emergency data={emergency} />
        </div>
      ))}
      <CustomModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        requestId={requestId}
        data={selectedEmergencyReq ?? {}}
      />
    </>
  );
};

export default EmergencyList;
