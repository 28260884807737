import { Box, Flex } from "@chakra-ui/layout";
import { useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import LeftHome from "../components/Sidebar/LeftHome";
import TopNav from "../components/TopNav/TopNav";
import DriverTable from "../components/driver/DriverTable";
import useDriver from "../hooks/useDriver";

const HomeWithNav = () => {
  const [isMobile] = useMediaQuery("(max-width: 425px)");
  const [city, setCity] = useState("");
  const [organizationName, setOrganizationNmae] = useState("");
  // const isUserAuthenticated = useAuth()
  const { drivers, deleteDriver } = useDriver()

  useEffect(() => {
    setCity(localStorage.getItem('city'));
    setOrganizationNmae(localStorage.getItem('organization_name'));
  }, []);

  return (
    <Flex direction={isMobile ? "column" : "row"}>
      <Box w={isMobile ? "100%" : "16vw"} zIndex={1} bg="#ffffff">
        <LeftHome />
      </Box>
      <Box w="100%" overflow="hidden">
        <Box h="40px" zIndex={1} bg="#ffffff" position="relative">
          <TopNav city={city} organizationName={organizationName} />
        </Box>
        <Box marginTop={20}>
          <DriverTable data={drivers} handleDeleteDriver={deleteDriver} />
        </Box>
      </Box>
    </Flex>
  );
};

export default React.memo(HomeWithNav);
