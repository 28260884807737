// import Ambulance from './../assets/images/markers/Ambulance.svg'
// import Bomberos from './../assets/images/markers/Bomberos.svg'
// import Doctor from './../assets/images/markers/Doctor.svg'
// import Carabineros from './../assets/images/markers/Carabineros.svg'
// import Grua from './../assets/images/markers/Grua.svg'
import EmergencyRed from "./../assets/images/markers/emergencyRed.png";
import EmergencyYellow from "./../assets/images/markers/emergencyYellow.png";
import PatrolVehicle from "./../assets/images/markers/patrolVehicle.png";
// import Mecanico from './../assets/images/markers/Mecanico.svg'
// import Patrol from './../assets/images/markers/Patrol.svg'
// import Patrol1 from './../assets/images/markers/Patrol1.svg'
// import Promo from './../assets/images/markers/Promo.svg'
// import RedCross from './../assets/images/markers/RedCross.svg'
// import CE2 from './../assets/images/markers/CE2.svg'
// import CE3 from './../assets/images/markers/CE3.svg'
// import CE4 from './../assets/images/markers/CE4.svg'
// import CE5 from './../assets/images/markers/CE5.svg'
// import CE6 from './../assets/images/markers/CE6.svg'
// import CE7 from './../assets/images/markers/CE7.svg'
// import CE8 from './../assets/images/markers/CE8.svg'
// import CE9 from './../assets/images/markers/CE9.svg'
// import CE10 from './../assets/images/markers/CE10.svg'
// import E2 from './../assets/images/markers/E2.svg'
// import E3 from './../assets/images/markers/E3.svg'
// import E4 from './../assets/images/markers/E4.svg'
// import E5 from './../assets/images/markers/E5.svg'
// import E6 from './../assets/images/markers/E6.svg'
// import E7 from './../assets/images/markers/E7.svg'
// import E8 from './../assets/images/markers/E8.svg'
// import E9 from './../assets/images/markers/E9.svg'
// import E10 from './../assets/images/markers/E10.svg'
// import CT2 from './../assets/images/markers/CT2.svg'
// import CT3 from './../assets/images/markers/CT3.svg'
// import CT4 from './../assets/images/markers/CT4.svg'
// import CT5 from './../assets/images/markers/CT5.svg'
// import CT6 from './../assets/images/markers/CT6.svg'
// import CT7 from './../assets/images/markers/CT7.svg'
// import CT8 from './../assets/images/markers/CT8.svg'
// import CT9 from './../assets/images/markers/CT9.svg'
// import CT10 from './../assets/images/markers/CT10.svg'
// import T2 from './../assets/images/markers/T2.svg'
// import T3 from './../assets/images/markers/T3.svg'
// import T4 from './../assets/images/markers/T4.svg'
// import T5 from './../assets/images/markers/T5.svg'
// import T6 from './../assets/images/markers/T6.svg'
// import T7 from './../assets/images/markers/T7.svg'
// import T8 from './../assets/images/markers/T8.svg'
// import T9 from './../assets/images/markers/T9.svg'
// import T10 from './../assets/images/markers/T10.svg'
// import W2 from './../assets/images/markers/W2.svg'
// import W3 from './../assets/images/markers/W3.svg'
// import W4 from './../assets/images/markers/W4.svg'
// import W5 from './../assets/images/markers/W5.svg'
// import W6 from './../assets/images/markers/W6.svg'
// import W7 from './../assets/images/markers/W7.svg'
// import W8 from './../assets/images/markers/W8.svg'
// import W9 from './../assets/images/markers/W9.svg'
// import W10 from './../assets/images/markers/W10.svg'
// import CW2 from './../assets/images/markers/CW2.svg'
// import CW3 from './../assets/images/markers/CW3.svg'
// import CW4 from './../assets/images/markers/CW4.svg'
// import CW5 from './../assets/images/markers/CW5.svg'
// import CW6 from './../assets/images/markers/CW6.svg'
// import CW7 from './../assets/images/markers/CW7.svg'
// import CW8 from './../assets/images/markers/CW8.svg'
// import CW9 from './../assets/images/markers/CW9.svg'
// import CW10 from './../assets/images/markers/CW10.svg'
// import { DRIVER_GENDER } from '../utils/config'

// // Offline Driver Icons STARTS
// import CT_BG_2 from './../assets/images/markers/Background/CT/CT2.svg'
// import CT_BG_3 from './../assets/images/markers/Background/CT/CT3.svg'
// import CT_BG_4 from './../assets/images/markers/Background/CT/CT4.svg'
// import CT_BG_5 from './../assets/images/markers/Background/CT/CT5.svg'
// import CT_BG_6 from './../assets/images/markers/Background/CT/CT6.svg'
// import CT_BG_7 from './../assets/images/markers/Background/CT/CT7.svg'
// import CT_BG_8 from './../assets/images/markers/Background/CT/CT8.svg'
// import CT_BG_9 from './../assets/images/markers/Background/CT/CT9.svg'
// import CT_BG_10 from './../assets/images/markers/Background/CT/CT10.svg'

// import T_BG_2 from './../assets/images/markers/Background/T/Tb2.svg'
// import T_BG_3 from './../assets/images/markers/Background/T/Tb3.svg'
// import T_BG_4 from './../assets/images/markers/Background/T/Tb4.svg'
// import T_BG_5 from './../assets/images/markers/Background/T/Tb5.svg'
// import T_BG_6 from './../assets/images/markers/Background/T/Tb6.svg'
// import T_BG_7 from './../assets/images/markers/Background/T/Tb7.svg'
// import T_BG_8 from './../assets/images/markers/Background/T/Tb8.svg'
// import T_BG_9 from './../assets/images/markers/Background/T/Tb9.svg'
// import T_BG_10 from './../assets/images/markers/Background/T/Tb10.svg'

// import W_BG_2 from './../assets/images/markers/Background/W/W2b.svg'
// import W_BG_3 from './../assets/images/markers/Background/W/W3b.svg'
// import W_BG_4 from './../assets/images/markers/Background/W/W4b.svg'
// import W_BG_5 from './../assets/images/markers/Background/W/W5b.svg'
// import W_BG_6 from './../assets/images/markers/Background/W/W6b.svg'
// import W_BG_7 from './../assets/images/markers/Background/W/W7b.svg'
// import W_BG_8 from './../assets/images/markers/Background/W/W8b.svg'
// import W_BG_9 from './../assets/images/markers/Background/W/W9b.svg'
// import W_BG_10 from './../assets/images/markers/Background/W/W10b.svg'

// import WE2_BG_2 from './../assets/images/markers/Background/CW/CWb2.svg'
// import WE2_BG_3 from './../assets/images/markers/Background/CW/CWb3.svg'
// import WE2_BG_4 from './../assets/images/markers/Background/CW/CWb4.svg'
// import WE2_BG_5 from './../assets/images/markers/Background/CW/CWb5.svg'
// import WE2_BG_6 from './../assets/images/markers/Background/CW/CWb6.svg'
// import WE2_BG_7 from './../assets/images/markers/Background/CW/CWb7.svg'
// import WE2_BG_8 from './../assets/images/markers/Background/CW/CWb8.svg'
// import WE2_BG_9 from './../assets/images/markers/Background/CW/CWb9.svg'
// import WE2_BG_10 from './../assets/images/markers/Background/CW/CWb10.svg'
// Offline Driver Icons ENDS

const getSuitableMarker = (isHighlightedMarker, isEmergencyMarker) => {
  if (isHighlightedMarker) {
    return EmergencyYellow;
  } else if (isEmergencyMarker) {
    return EmergencyRed;
  } else {
    return PatrolVehicle;
  }
};
export const markerIcon = ({ isHighlightedMarker, isEmergencyMarker }) => {
  return getSuitableMarker(isHighlightedMarker, isEmergencyMarker);
};
