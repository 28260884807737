import {
  Box,
  ButtonGroup,
  Container,
  Divider,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { InputControl, ResetButton, SubmitButton } from "formik-chakra-ui";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Card } from "../../components/Card";
import useAuthentication from "../../hooks/useAuthentication";
// import ForgotPasswordModal from '../../components/ForgotPasswordModal'

export default function Register() {
  const navigate = useNavigate();
  const { registerUser } = useAuthentication();

  const initialValues = {
    fullName: "",
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().min(3).required(),
    email: Yup.string().email().required(),
    password: Yup.string().min(6).required(),
  });

  const navigateToLogin = () => {
    navigate("login");
  };

  return (
    <Container>
      <Heading textAlign="center" my={12}>
        Register
      </Heading>
      <Card maxW="md" mx="auto" mt={4}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={registerUser}
        >
          <Form>
            <VStack alignItems="flex-start" spacing={6}>
              {/* {error && error === 'CredentialsSignin' && (
                  <Text textColor='red.400'>Please check your credentials.</Text>
                )} */}
              <InputControl name="fullName" label="Full Name" id="fullName" />
              <InputControl name="email" label="Email" id="email" />
              <InputControl
                name="password"
                label="Password"
                id="password"
                inputProps={{ type: "password" }}
              />
              <ButtonGroup>
                <SubmitButton>Sign up</SubmitButton>
                {/* <ResetButton colorScheme='primary'>Reset</ResetButton> */}
              </ButtonGroup>
              <Divider />
              <Box alignItems="center">
                <Text
                  fontSize="sm"
                  as="u"
                  textColor={"blue.900"}
                  style={{ cursor: "pointer" }}
                  onClick={navigateToLogin}
                >
                  Back to Login
                </Text>
              </Box>
            </VStack>
          </Form>
        </Formik>
      </Card>
    </Container>
  );
}
