import {
  AspectRatio,
  Avatar,
  Box,
  chakra,
  CloseButton,
  Heading,
  HStack,
  useBreakpointValue,
  Text,
  VStack,
  IconButton,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt, FaLocationArrow, FaCalendarAlt } from "react-icons/fa";
import moment from "moment";

const MotionBox = motion(Box);

export default function EmergencyDetailMotionBox({ isOpen, onClose, driver }) {
  const { t } = useTranslation();

  const margin = useBreakpointValue({ base: "10", lg: "8" });
  const width = useBreakpointValue({ base: "100vw", lg: "25vw" });
  const borderRadius = useBreakpointValue({ base: "lg", md: "lg" });
  const height = useBreakpointValue({ base: "20vh" });

  const base = {
    initial: { y: 100, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    exit: { y: 100, opacity: 0 },
    transition: {
      duration: 0.5,
      ease: [0.6, -0.05, 0.01, 0.9],
    },
  };

  const lg = {
    initial: { x: -250, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: -250, opacity: 0 },
    transition: {
      duration: 0.5,
      ease: [0.6, -0.05, 0.01, 0.9],
    },
  };

  const { initial, animate, exit, transition } = useBreakpointValue({
    base: base,
    lg: lg,
  });

  return (
    <AnimatePresence exitBeforeEnter>
      {isOpen && (
        <MotionBox
          margin={margin}
          minW={width}
          minH={height}
          // bgColor='white'
          bgColor="whiteAlpha.900"
          backdropFilter="blur(3px)"
          position="absolute"
          bottom={{ base: 0, lg: 4 }}
          left={"16vw"}
          borderRadius={borderRadius}
          padding={{ base: 4, md: 8 }}
          initial={initial}
          animate={animate}
          exit={exit}
          transition={transition}
          spacing={12}
        >
          <HStack alignItems="flex-start" justifyContent="space-between">
            <VStack alignItems="flex-start">
              <Heading size="md">{driver.name}</Heading>
              <HStack>
                <IconButton
                  aria-label="call-driver"
                  colorScheme="red"
                  as="a"
                  icon={<FaLocationArrow />}
                  size="sm"
                />
                <Text>{driver.location}</Text>
              </HStack>

              <HStack>
                <IconButton
                  aria-label="call-driver"
                  colorScheme="green"
                  as="a"
                  href={`tel:${driver.phoneNumber}`}
                  icon={<FaPhoneAlt />}
                  size="sm"
                />
                <Text>{driver.phoneNumber}</Text>
              </HStack>

              <HStack>
                <IconButton
                  aria-label="call-driver"
                  colorScheme="blue"
                  as="a"
                  icon={<FaCalendarAlt />}
                  size="sm"
                />
                <Text>{moment(driver.created).format("YYYY-MM-DD HH:mm")}</Text>
              </HStack>
            </VStack>
            {/* <Avatar
              size='xl'
              name={driver.fullName}
              src={driver.images.profile.url}
            /> */}
          </HStack>
          {/* <AspectRatio ratio={16 / 9} minW={40} my={2}>
            <Image
              borderRadius='md'
              aria-label={driver.vehicle.registrationNumber}
              objectFit='fill'
              src={driver.vehicle.images.vehicle.url}
            />
          </AspectRatio> */}
          <HStack justifyContent="flex-end" mt={2}>
            <CloseButton onClick={onClose} alignSelf="flex-end" />
          </HStack>
        </MotionBox>
      )}
    </AnimatePresence>
  );
}
