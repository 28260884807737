import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Container,
  Divider,
  Heading,
  HStack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { InputControl, ResetButton, SubmitButton } from "formik-chakra-ui";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Card } from "../../components/Card";
import useAuthentication from "../../hooks/useAuthentication";
// import ForgotPasswordModal from '../../components/ForgotPasswordModal'

export default function Login() {
  const navigate = useNavigate();
  const { loginUser } = useAuthentication();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().min(3).required(),
  });

  const navigateToSignUp = () => { navigate("signup"); };

  const navigateToForgotPassword = () => { navigate("forgot-password"); };

  return (
    <Container>
      <Heading textAlign="center" my={12}>
        Login
      </Heading>
      <Card maxW="md" mx="auto" mt={4}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={loginUser}
        >
          <Form>
            <VStack alignItems="flex-start" spacing={6}>
              {/* {error && error === 'CredentialsSignin' && (
                  <Text textColor='red.400'>Please check your credentials.</Text>
                )} */}
              <InputControl name="email" label="Email" id="email" />
              <InputControl
                name="password"
                label="Password"
                id="password"
                inputProps={{ type: "password" }}
              />
              <ButtonGroup>
                <SubmitButton>Login</SubmitButton>
                {/* <ResetButton colorScheme='primary'>Reset</ResetButton> */}
              </ButtonGroup>
              <Divider />
              {/* <ForgotPasswordModal /> */}
              <Box
                alignItems="center"
                style={{ cursor: "pointer", display: "flex" }}
                onClick={navigateToSignUp}
              >
                <Text fontSize="sm">New user?&nbsp;</Text>
                <Text fontSize="sm" as="u" textColor={"blue.900"}>
                  Sign up
                </Text>
              </Box>
              <Box
                alignItems="center"
                style={{ cursor: "pointer", display: "flex" }}
                onClick={navigateToForgotPassword}
              >
                <Text fontSize="sm" as="u" textColor={"blue.900"}>
                  Forgot Password?
                </Text>
              </Box>
            </VStack>
          </Form>
        </Formik>
      </Card>
    </Container>
  );
}
