import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, Container, SkeletonText } from "@chakra-ui/react";
import { theme } from "./utils/theme";
import "@fontsource/inter/variable.css";
import { SWRConfig } from "swr";
import api from "./lib/api-client";
import GeolocationContextProvider from "./contexts/GeolocationContext";
import BookingContextProvider from "./contexts/BookingContext";

import "flag-icon-css/css/flag-icons.min.css";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import EmergencyReqContextProvider from "./contexts/EmergencyReqContext";
import EmergencyReqDetailContextProvider from "./contexts/EmergencyReqDetailContext";

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "it", "fr", "es", "de", "pt"],
    fallbackLng: "es",
    lng:"es" ,
    debug: false,
    // Options for language detector
    detection: {
      order: ["path", "cookie", "navigator", "htmlTag"],
      caches: ["cookie"],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

const LoadingSkeleton = () => {
  return (
    <Container my={16}>
      <SkeletonText />
    </Container>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Suspense fallback={<LoadingSkeleton />}>
        <SWRConfig value={{ fetcher: api }}>
          <GeolocationContextProvider>
            <BookingContextProvider>
              {/* <OrganizationMonitorContextProvider> */}
              <EmergencyReqContextProvider>
                <EmergencyReqDetailContextProvider>
                  <App />
                </EmergencyReqDetailContextProvider>
              </EmergencyReqContextProvider>
              {/* </OrganizationMonitorContextProvider> */}
            </BookingContextProvider>
          </GeolocationContextProvider>
        </SWRConfig>
      </Suspense>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
