import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const DriverImageModal = ({ openModal, onClose, images: defaultImages }) => {

  const [images, setImages] = useState(defaultImages.map((url) => ({ url, isZoomed: false })))

  // useEffect(() => {
  //   console.log('first', )
  // }, [])

  const handleZoomChange = useCallback((index, shouldZoom) => {
    console.log('shouldZoom', shouldZoom)
    setImages(prevImages => {
      const _prevImages = [...prevImages]
      _prevImages[index].isZoomed = shouldZoom
      return _prevImages
    })
  }, [])


  return (
    <Modal isOpen={openModal} onClose={onClose} size={400}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody padding='20' >
          <div style={{
            overflowY: 'scroll',
            maxHeight: '70vh'
          }}>
            <div className="row" >
              {
                images?.map((image, index) => {
                  return (
                    <Box marginRight={2}>
                      <ControlledZoom isZoomed={image.isZoomed} onZoomChange={(shouldZoom) => handleZoomChange(index, shouldZoom)}>
                        <Image
                          src={image?.url}
                          style={{
                            width: '300px',
                            objectFit: 'cover',
                            objectPosition: '50% 50%'
                          }}
                        />
                      </ControlledZoom>
                    </Box>
                  )
                })}
            </div>

          </div>

        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DriverImageModal;
