import { Box, Button, Flex, Spacer, Text } from "@chakra-ui/react";
import useAuthentication from "../../hooks/useAuthentication";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";

function TopNavBar({ city, organizationName }) {
  const { logoutUser, handleNavigation } = useAuthentication();
  const isMonitor = window.location.pathname === "/user";

  const getTabName = () => {
    switch (window.location.pathname) {
      case "/drivers":
        return "Choferes";
      case "/emergencies":
        return "Emergencias";
      case "/users":
        return "Usuarios";
    }
  };

  return (
    <Flex
      bg="gray.50"
      height={"16"}
      p={4}
      style={{ justifyContent: "space-between" }}
    >
      <Box p="2">
        <Text fontSize="xl" fontWeight="bold">
          {isMonitor ? organizationName : getTabName()}
        </Text>
      </Box>
      <Box p="2" fontWeight="bold">
        <Text fontSize="lg">{city} ({localStorage.getItem('user_name')})</Text>
      </Box>
      <Box marginLeft={2}>
        <Button
          marginRight={2}
          onClick={() =>
            handleNavigation(
              window.location.pathname === "/user" ? "/drivers" : "/user",
            )
          }
        >
          {isMonitor ? "Admin" : "Monitor"}
        </Button>
        <Button colorScheme={"red"} size='sm' onClick={logoutUser}>
          Logout
        </Button>
      </Box>
    </Flex>
  );
}

export default TopNavBar;
