import axios from "axios";
import constant from "../constant";

const api = axios.create({
  baseURL: constant.BASE_URL_CLIENT,
  withCredentials: true,
  headers: {
    newtoken:
      typeof window !== "undefined"
        ? localStorage.getItem("access_token")
        : "token not available",
  },
});

/** @type import('axios').AxiosInterceptorManager */
api.interceptors.response.use(
  (/** @type import('axios').AxiosResponse */ response) => {
    return response.data;
  },

  (/** @type import('axios').AxiosError */ error) => {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  },
);

export default api;
