import { Marker } from "@react-google-maps/api";
import React from "react";
import { useGeolocation } from "../contexts/GeolocationContext";
import { markerIcon } from "../lib/market-icon";

export default function Drivers({
  emergencyRequests,
  selectedEmergencyReq,
  setSelectedEmergencyReq,
}) {
  const { position } = useGeolocation();

  return (
    <>
      {emergencyRequests?.map((emergencyReq, index) => {
        const coords = {
          lat: emergencyReq.locationCoordinates.coordinates[0],
          lng: emergencyReq.locationCoordinates.coordinates[1],
        };
        if (selectedEmergencyReq) {
        }
        const isHighlightedMarker =
          selectedEmergencyReq?._id === emergencyReq?._id;
        const isEmergencyMarker = !!emergencyReq?._id;
        return (
          <Marker
            key={emergencyReq._id}
            position={coords}
            icon={markerIcon({ isHighlightedMarker, isEmergencyMarker })}
            onClick={() => setSelectedEmergencyReq(emergencyReq)}
          />
        );
      })}
    </>
  );
}
