import {
  CloseButton,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'
import {
  FaTrash,
} from 'react-icons/fa'
import ExpandedDriverDetails from './ExpandedDriverDetails'

export default function DriverTable({ data = [], handleDeleteDriver }) {
  const columns = [
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
      minWidth: "40px",
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
      sortable: true,
      // minWidth: "156px",
    },
    {
      name: "Driving License Number",
      selector: (row) => row.drivingLicenseNumber,
    },
    {
      name: "Vehicle Reg No.",
      selector: (row) => row.vehicle.registrationNumber,
    },
    {
      name: 'Vehicle Type',
      cell: () => <p>-</p>
    }, {
      name: 'Action',
      cell: (item) => <IconButton
        aria-label="Delete Admin"
        colorScheme="red"
        isRound
        size="sm"
        icon={<FaTrash />}
        onClick={() => handleDeleteDriver(item._id)}
      />
    },
  ]

  
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = data.filter(
    (item) =>
      item.fullName.toLowerCase().includes(filterText.toLowerCase()) ||
      item.drivingLicenseNumber
        .toLowerCase()
        .includes(filterText.toLowerCase()) ||
      item.vehicle.registrationNumber
        .toLowerCase()
        .includes(filterText.toLowerCase()) ||
      item.verificationStatus.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      columns={columns}
      data={filteredItems}
      pagination
      persistTableHead
      paginationResetDefaultPage={resetPaginationToggle}
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      expandableRows
      expandableRowsComponent={ExpandedDriverDetails}
      // actions={actionsMemo}
      onColumnOrderChange={(cols) => console.log(cols)}
    />
  );
}

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <InputGroup maxW='sm'>
      <Input
        id="search"
        type="text"
        placeholder="Filter by (Name, Vehicle or DL number)"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
      <InputRightElement>
        <CloseButton
          type="button"
          onClick={onClear}
          display={filterText ? "inline-flex" : "none"}
        />
      </InputRightElement>
    </InputGroup>
  </>
);
