import { useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/layout";
import LeftHome from "../components/Sidebar/LeftHome";
import TopNav from "../components/TopNav/TopNav";
import HomePage from "./HomePage";

const HomeWithNav = () => {
  const [isMobile] = useMediaQuery("(max-width: 425px)");
  const [city, setCity] = useState("");
  const [organizationName, setOrganizationNmae] = useState("");

  // const isUserAuthenticated = useAuth()

  useEffect(() => {
    setCity(localStorage.getItem('city'));
    setOrganizationNmae(localStorage.getItem('organization_name'));
  }, []);

  return (
    <Flex direction={isMobile ? "column" : "row"}>
      <Box w={isMobile ? "100%" : "16vw"} zIndex={1} bg="#ffffff">
        <LeftHome />
      </Box>
      <Box w="100%" overflow="hidden">
        <Box h="40px" zIndex={1} bg="#ffffff" position="relative">
          <TopNav city={city} organizationName={organizationName} />
        </Box>
        <HomePage />
      </Box>
    </Flex>
  );
};

export default HomeWithNav;
