import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { GoogleMap, Marker } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IconCE7 from "../assets/images/markers/CE7.svg";
import IconCT7 from "../assets/images/markers/CT7.svg";
import Drivers from "../components/Drivers";
import EmergencyDetailMotionBox from "../components/EmergencyDetailMotionBox";
import { useEmergencyReq } from "../contexts/EmergencyReqContext";
import { useEmergencyReqDetail } from "../contexts/EmergencyReqDetailContext";
import { useGeolocation } from "../contexts/GeolocationContext";
import useEmergencyRequest from "../hooks/useEmergencyRequest";
import api from "../lib/api-client";
import { snazzyMapStyle } from "../lib/map-style";
import { DEFAULT_LOCATION, DRIVER_GENDER, VEHICLE_TYPE } from "../utils/config";
import ConfirmSPBooking from "./ConfirmSPBooking";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 33.4489,
  lng: 70.6693,
};

export default function HomePage() {
  const { t } = useTranslation();

  const toast = useToast();
  const [map, setMap] = useState(
    /** @type google.maps.Map*/
    (null),
  );

  const { position, error } = useGeolocation();
  const emergencyRequests = useEmergencyReq();
  const {
    isShowEmergencyReqModal,
    selectedEmergencyReqFromContext,
    sendSPPayload,
    onOpen: setReq,
  } = useEmergencyReqDetail();
  const [selectedEmergencyReq, setSelectedEmergencyReq] = useState(null);

  const onMapLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const [mapCenter, setMapCenter] = useState({
    latitude: DEFAULT_LOCATION.LAT,
    longitude: DEFAULT_LOCATION.LONG,
  });

  const [debouncedMapCenter] = useDebouncedValue(mapCenter, 500);

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("x-is-reload"))) {
      window.location.reload();
      localStorage.setItem("x-is-reload", JSON.stringify(true));
    }
    return () => {
      localStorage.setItem("x-is-reload", JSON.stringify(false));
    };
  }, []);

  useEffect(() => {
    // console.log("first", selectedEmergencyReq);
    setReq(selectedEmergencyReq);
  }, [selectedEmergencyReq]);

  useEffect(() => {
    console.log("sendSPPayload", sendSPPayload);
    if (isShowEmergencyReqModal) {
      console.log({ isShowEmergencyReqModal, selectedEmergencyReqFromContext });
      setSelectedEmergencyReq(selectedEmergencyReqFromContext);
    }
  }, [selectedEmergencyReqFromContext]);

  useEffect(() => {
    console.log("debouncedMapCenter", debouncedMapCenter);
  }, []);

  const { height, width } = useViewportSize();

  useEffect(() => {
    console.log("Position", position);
    if (position != null && map != null) {
      map.panTo({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    }
  }, [position, map]);

  return (
    // <VStack w='100vw' h='100vh'>
    <VStack w={width} h={height}>
      <Box boxSize={"100%"} position="absolute" top={0} left={0}>
        <ConfirmSPBooking
          emergencyRequests={emergencyRequests}
          selectedEmergencyReq={selectedEmergencyReq}
          setSelectedEmergencyReq={setSelectedEmergencyReq}
        />
      </Box>

      {/* UNUSED CODE STARTS */}

      {/* <EmergencyDetailMotionBox
        driver={selectedEmergencyReq}
        isOpen={selectedEmergencyReq}
        onClose={() => setSelectedEmergencyReq(null)}
      /> */}

      {/* <HStack alignSelf='stretch' justifyContent='space-between' px={2}>
        <IconButton
          aria-label='current-location'
          colorScheme={'primary'}
          icon={<FaFilter />}
          onClick={onOpen}
        />
        <ButtonGroup colorScheme='primary'>
          <Button as='a' href={t('helpUrl')} target='_blank'>
            {t('page1.help')}
          </Button>
          <Link to='/booking'>
            <Button>{t('page1.findTaxi')}</Button>
          </Link>
        </ButtonGroup>
        <VStack>
          <LanguageSelector />
        </VStack>
      </HStack> */}

      {/* <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("filter.filter")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack
              bgColor="white"
              padding={4}
              borderRadius="lg"
              zIndex="modal"
              spacing={{ base: 2, md: 8 }}
            >
              <FormControl>
                <FormLabel fontSize={{ base: "sm", md: "md" }}>
                  {t("filter.taxiType")}
                </FormLabel>
                <Select
                  onChange={(e) => setVehicleType(e.target.value)}
                  defaultValue={vehicleType}
                >
                  <option value="ALL">{t("filter.all")}</option>
                  <option value={VEHICLE_TYPE.TAXI}>Taxi</option>
                  <option value={VEHICLE_TYPE.EXECUTIVE_TAXI}>Premium</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel fontSize={{ base: "sm", md: "md" }}>
                  {t("filter.minimumPassengers")}
                </FormLabel>
                <Input
                  type="number"
                  name="passengers"
                  id="passengers"
                  value={passengerCapacity}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setPassengerCapacity("");
                      return;
                    }
                    setPassengerCapacity(parseInt(e.target.value));
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={{ base: "sm", md: "md" }}>
                  {t("filter.acceptCreditCard")}
                </FormLabel>
                <RadioGroup
                  value={acceptCreditCard}
                  onChange={setAcceptCreditCard}
                >
                  <Stack direction="row">
                    <Radio value="1">{t("filter.yes")}</Radio>
                    <Radio value="0">{t("filter.no")}</Radio>
                    <Radio value="2">{t("filter.all")}</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel fontSize={{ base: "sm", md: "md" }}>
                  {t("filter.gender")}
                </FormLabel>
                <RadioGroup value={gender} onChange={setGender}>
                  <Stack direction="row">
                    <Radio value={DRIVER_GENDER.all}>
                      {t("filter.all")}
                    </Radio>
                    <Radio value={DRIVER_GENDER.male}>
                      {t("filter.male")}
                    </Radio>
                    <Radio value={DRIVER_GENDER.female}>
                      {t("filter.female")}
                    </Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <Checkbox
                size="lg"
                alignSelf="flex-start"
                onChange={(e) => setSpeaksEnglish(e.target.checked)}
              >
                {t("filter.englishSpoken")}
              </Checkbox>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                setAcceptCreditCard("2");
                setPassengerCapacity("");
                setVehicleType("");
                setGender(DRIVER_GENDER.all);
              }}
            >
              {t("filter.clearFilter")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}

      {/* UNUSED CODE ENDS */}
    </VStack>
  );
}
