import constant from "../constant";
import axiosInstance from "../interceptor";

const useUserLocation = () => {

  const getMonitorByPrefix = async () => {
    try {
      const monitor = await axiosInstance.get(
        `${constant.BASE_URL_ADMIN}/monitor/${constant.SUB_DOMAIN_PREFIX}`,
      );
      // localStorage.setItem("latitude", monitor.data?.latitude);
      // localStorage.setItem("longitude", monitor.data?.longitude);
      // localStorage.setItem("organization_name", monitor.data?.organization_name);
      // localStorage.setItem("city", monitor.data?.city);
      return monitor;
    } catch (e) {
      console.log("Error in getting monitor", e);
    }
  };

  return {
    getMonitorByPrefix,
  };
};

export default useUserLocation;
